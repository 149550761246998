@import 'shared';

.hex-container {
  width: 100px;
  position: absolute;
  z-index: 30;
  top: -56px;
  display: flex;
  align-items: center;

  .hex-title {
    position: relative;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 20;
    color: white;
    @include title-7();
    text-align: center;
    line-height: 1.2em;
  }

  .hex-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  &.large {
    width: 190px;
    height: 219px;
    top: -236px;
    right: -94px;

    .hex-title {
      font-family: 'Bungee', cursive;
      font-size: 36px;
    }
  }

  &.medium {
    width: 175px;
    height: 202px;

    .hex-title {
      font-family: 'Bungee', cursive;
      font-size: 36px;
    }
  }

  &.small {
    width: 134px;
    height: 154px;

    .hex-title {
      @include title-4();
    }
  }

  &.tiny {
    width: 100px;
    height: 115px;

    .hex-title {
      @include title-7();
    }
  }
}
