@import 'shared';

.modal {
  // make momentum scrolling smooth on IOS Safari
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

.care-body .modal-backdrop {
  background-color: $tan;
  &.show {
    opacity: .75;
  }
}

.care-intro-modal {
  &.modal-lg {
    max-width: map-get($breakpoints, lg);
  }

  .modal-content {
    border: none;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    margin: auto;
  }

  .hex-group {
    width: 100%;
    margin: auto;
  }

  .hex-background {
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  .hex-container {
    top: auto;
    left: auto;
    cursor: pointer;

    .hex-title {
      @include title-4();
      line-height: 1em;
    }
  }

  .hex-one .hex-container {
    margin-top: -50px;
    margin-left: -87.5px;
    left: 0;
  }

  .hex-two .hex-container {
    margin: auto;
    margin-top: $standard-margin;
    position: relative;

    .hex-title {
      color: map-get($grayColors, medium);
      width: 75%;
    }

    .hex-svg {
      opacity: .1;
    }
  }

  .care-intro-video,
  .care-intro-content {
    padding: $standard-padding;  
    text-align: center;
    width: 100%;

    .intro-title {
      @include title-3();
      width: 40%;
      margin: auto;
      margin-bottom: $standard-margin;

      .italic {
        font-style: italic;
      }
    }

    .intro-video {
      width: 100%;
      margin: auto;
      margin-top: 120px;
    }

    .intro-copy {
      @include stats-copy();
      font-size: 12px;
      text-align: left;
      margin-bottom: $standard-margin;
    }
  }

  .care-intro-video {
    .intro-title {
      margin-top: 100px;
    }
  }

  .care-intro-content {
    background-color: $tan-opacity;
    padding: 30px;
    border: 1px solid #4a4a4a;
    border-style: dashed;

    .intro-title {
      width: 100%;
      text-align: left;
    }
  }

  .intro-button {
    cursor: pointer;
    @include button-default();
    margin: 0;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-top: $standard-margin*2;
  }
}

// Example usage:
@media screen and (max-width:map-get($breakpoints, lg)) {
  .care-intro-modal {
    &.modal-lg {
      max-width: 500px;
    }

    .hex-background {
      top: 70px;
    }

    .hex-one .hex-container {
      margin: auto;
      position: relative;
    }

    .care-intro-video {
      .intro-title {
        margin-top: 100px;
      }
    }

    .care-intro-content {
      margin-bottom: $standard-margin*2;
      margin-top: 200px;
    }

    .hex-two .hex-container {
      width: 100px;
      height: 115px;

      .hex-title {
        font-size: 14px;
      }
    }

    .intro-button {
      width: 65%;
    }
  }
}

@media screen and (max-width:map-get($breakpoints, xs)) {
  .care-intro-modal {
    .modal-content {
      border: 1px solid #4a4a4a;
      border-style: dashed;
    }

    .hex-background {
      display: none;
    }

    .care-intro-video {
      padding: $standard-padding-2;
      border: 0px solid #4a4a4a;
      border-style: dashed;

      .intro-title {
        width: 100%;
        margin-top: $standard-padding-2*2;
        text-align: left;
      }

      .hex-two .hex-container {
        display: none;
      }
    }

    .care-intro-content {
      margin-bottom: 0;
      padding: 30px;
      border: 0px solid #4a4a4a;

      .intro-button {
        cursor: pointer;
        position: relative;
        margin-top: $standard-margin;
        margin-bottom: 0;
        width: 100%;
        margin-left: 0;
        padding-left: 25%;
        padding-right: 25%;
      }
    }
  }
}

@include respond-below(lg) {
  .care-intro-modal .care-intro-video {
    .intro-video {
      margin-top: 180px;
    }
  }
}

@include respond-below(xs) {
  .care-intro-modal .care-intro-video {
    .intro-video {
      margin-top: 40px;
    }
  }

  .care-intro-modal .care-intro-content {
    margin-top: 0px;
  }

}