@import 'mediaQueries';

$max-width: 1366px;

$transition: all .5s ease;
$transition-fast: all .2s ease;
$transition-extra-fast: all .1s ease;
$transition-slow: all 1s ease;
$transition-extra-slow: all 2s ease;

$transition-delay: 0.1s;

$tan: #fff5d9;
$tan-opacity: rgba(255, 245, 217, 0.5);
$tan-opacity-2: rgba(255, 245, 217, 0.75);

$dark-gray: #4a4a4a;
$medium-gray: #7f7f7f;
$light-gray: #f2f2f2;

$home-text-color: #328FCE;
$home-highlight-1: #0060A0;

$section-dark-text-color: #38393B;

$retirement-text-color: #9F87E8;
$home-highlight-1: #0060A0;

$title-margin-bottom: 10px;
$title-margin-bottom-2: 25px;
$body-margin-bottom: 10px;
$standard-margin-bottom: 50px;
$standard-margin-bottom-2: 20px;

$standard-margin: 20px;
$standard-margin-2: 10px;
$standard-padding: 50px;
$standard-padding-2: 20px;

@mixin title-1() {
  font-family: 'IBM Plex Mono', bold;
  font-weight: bold;
  font-size: 30px;
  line-height: 1.3em;
}

@mixin title-2() {
  font-family: 'Bungee', cursive;
  font-size: 32px;
  line-height: 1.5em;
}

@mixin title-3() {
  font-family: 'IBM Plex Mono', bold;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3em;
}

@mixin title-4() {
  font-family: 'Bungee', cursive;
  font-size: 24px;
  line-height: 1.5em;
}

@mixin title-5() {
  font-family: 'Bungee', cursive;
  font-size: 20px;
  line-height: 1.5em;
}

@mixin title-6() {
  font-family: 'IBM Plex Sans', sans;
  font-size: 15px;
  line-height: 1.5em;
}

@mixin title-7() {
  font-family: 'Bungee', cursive;
  font-size: 14px;
  line-height: 1.3em;
}

@mixin title-8() {
  font-family: 'Bungee', cursive;
  font-size: 16px;
  line-height: 1.3em;
}

@mixin stats-copy() {
  font-family: 'IBM Plex Mono', mono;
  font-size: 12px;
  line-height: 1.5em;
}

@mixin body-copy() {
  font-family: 'IBM Plex Sans', mono;
  font-size: 16px;
  line-height: 1.5em;
}

@mixin title-small() {
  font-family: 'IBM Plex Mono', bold;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.3em;
  text-transform: capitalize;
}

@mixin content-small() {
  font-family: 'IBM Plex Mono', italic;
  font-size: 12px;
  line-height: 1.3em;
}

@mixin button-default() {
  color: $tan;
  @include title-5();
  line-height: 1.2em;
  padding: $standard-padding-2;
  text-align: center;
  cursor: pointer;
  background-color: black;
  transition: $transition;
  border: 1px solid transparent;

  &:hover {
    background-color: $tan;
    background-color: transparent;
    color: black;
    transition: $transition;
    border: 1px solid black;
  }
}

$grayColors: (
  dark: #4a4a4a,
  medium: #7f7f7f,
  light: #f2f2f2
);

$homeColors: (
  main: #328fce,
  dark: #0060a0,
  light: #93c1e7,
  neon: #00ffff
);

$retirementColors: (
  main: #9f87e8,
  dark: #57448d,
  light: #c8b5ff,
  neon: #ff00ff
);

$schoolColors: (
  main: #2cb34b,
  dark: #008343,
  light: #82c562,
  neon: #00FF00
);

$workColors: (
  main: #fbb163,
  dark: #f15a22,
  light: #fed07f,
  neon: #fcee21
);

$communityColors: (
  main: #e0c200,
  dark: #a18b00,
  light: #fcee21,
  neon: #ffff00
);

$careColors: (
  main: #f16f8e,
  dark: #ed175e,
  light: #f7b0c4,
  neon: #ff459d
);

$individualColors: (
  main: #00aaa7,
  dark: #007e84,
  light: #63c7c9,
  neon: #00FFFF
);

$retirementColors: (
  main: #9f87e8,
  neon: #ff00ff,
  dark: #57448d,
  light: #C8B5FF
);

// From: https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/
// A map of breakpoints.
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px
);

// Get the small breakpoint.
$breakpoint: map-get($breakpoints, sm);

// Example usage:
@include respond-below(sm) {
  .element {
    font-weight: bold;
  }
}

.text-center {
  text-align: center;
}
