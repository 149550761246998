@import 'shared';

.modal {
  background-color: $tan-opacity;
}

.care-about-modal {
  padding: $standard-padding;
  
  &.modal-lg {
    max-width: map-get($breakpoints, lg);
  }

  .close-icon {
    width: 35px;
    position: absolute;
    cursor: pointer;
    top: $standard-padding;
    right: $standard-padding;
    opacity: .5;
    transition: $transition;
    z-index: 10;
  }

  .close-icon:hover {
    opacity: 1;
    transition: $transition;
  }

  .heart-icon {
    position: absolute;
    right: 50px;
    bottom: 50px;
    width: 50px;
    height: auto;
  }

  .privacy-policy {
    margin-top: 60px;
    display: inline-block;
    background-color: #4D4D4D;
    color: #FFF6D9;
    padding: 3px 20px;
    @include stats-copy();
    cursor: pointer;
    z-index: 100;
    transition: all 0.5s ease;
    border: 1px solid #4D4D4D; 

    &:hover {
      text-decoration: none;
      background-color: #FFF6D9;
      color: #4D4D4D;
      border: 1px solid #4D4D4D; 
    }
  }

  .peeps {
    width: 100%;
    height: auto;

  }

  .modal-content {
    padding: $standard-padding;
    border: 1px solid #4a4a4a;
    border-style: dashed;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    margin: auto;
    background-color: $tan-opacity-2;
  }

  .care-about-content {
    padding: $standard-padding-2;
    text-align: left;
    width: 100%;
  
    .about-title {
      @include title-1();
      color: map-get($grayColors, dark);
      font-size: 24px;
      text-align: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    .about-copy, .about-partner {
      @include stats-copy();
      color: map-get($grayColors, dark);
      font-size: 12px;
      text-align: left;
    }

    .about-copy  {
      margin-bottom: $standard-margin-bottom-2;
    }

    .about-partner {
      font-style: italic;
      margin-bottom: 0;
    }

    .italic {
      font-style: italic;
    }

    .bold {
      font-weight: bold;
    }
  }
}

@media screen and (max-width:map-get($breakpoints, xs)) {
  .care-about-modal {
    padding: 0;

    .privacy-policy {
      bottom: 140px;
    }

    .modal-content {
      padding: $standard-padding-2 0;
    }

    .close-icon {
      position: fixed;
      top: $standard-padding-2;
      right: $standard-padding-2;
      width: 50px;
      height: 50px;
      opacity: 1;
      padding: 10px;
      z-index: 10;
    }
  }
}
