//////////////////////////////////
// Responsive Media Queries
//////////////////////////////////

@mixin subtitle-formatting() {
  left: 15px;
  font-size: 11px;
  width: 80px;
  line-height: 14px;
  top: -100px;
}

@include respond-below(md) {

  .section {
    margin-top: 48vh;
    background-color: rgba(255,245,217, 0.9);
  }

  .section-intro-container {
    .section-content-container {
      margin-top: 0;
    }
  }

  .hear-this-section {
    margin-top: 250px !important;
    padding-bottom: 0;

    .hex-container {
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .next-button {
    width: 96%;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .section .lottie-hotspot {
    position: fixed;
    height: 50vh;
    width: 100%;
    left: auto;
    top: 0;
  }

  .first-provocation-container .provocation-hexagon {
    margin-left: 0;
  }

  .second-provocation-container .provocation-hexagon {
    margin-left: 0;
  }

  .watch-this-content-col {
    margin-left: 15px;
    margin-right: 15px;
  }

  .stat-background {
    display: none;
  }

  .stat-circle {
    height: 107px;
    width: 107px;
    font-size: 24px;
  }

  .stat-circle-container {
    display: inline-block;
    position: relative;
    width: 107px;
  }


  .section .top-content-container .todays-subtitle {
    @include subtitle-formatting();
    left: 0px;
    top: -60px;
  }

  .hear-this-section .hear-this-subtitle {
    @include subtitle-formatting();
    left: 0px;
    top: -35px;
  }

  .watch-this-content-row .watch-this-subtitle {
    @include subtitle-formatting();
    top: -70px;
    left: 0;
  }
  
  .section .build-row {
    margin-right: 0px;
    margin-left: 0px;
  }

  .build-this-content-row .build-this-subtitle {
    @include subtitle-formatting();
  }

  .section .hear-this-container {
    margin: 0 0 20px 0;
  }

  .build-item-img, .hear-item-img {
    max-width: 120px;
  }

  .watch-row .build-item-img {
    max-width: 100%;
  }

  .hear-item-img, .build-item-img-container {
    margin-bottom: 10px;
  }

  .hear-this-line, .build-item-line {
    display: none;
  }

  .section .top-content-container .hex-container {
    left: 0;
    right: 0;
    margin: auto;
  }

  .section .top-content-container {
    margin-top: 220px;
    .stat-background, .stat-background-bottom {
      display: none;
    }

    .todays-reality-content-container {
      border: 0;
    }

    .stat-item {
      background: white;
      padding-top: 20px;
      padding-bottom: 20px;
    }

  }

  .build-this-content-row {
    margin-top: 360px;
  }

  .hex-container.large {
    left: 0;
    right: 0;
    margin: auto;
  }

  .watch-this-content-row {
    margin-top: 160px;
    position: relative;

    .hex-container {
      position: absolute;
      top: -170px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .watch-item-img-container {
    margin-bottom: 15px;
    margin-top: 0px;
  }

  .section {
    .provocations-container {
      margin-top: 60px;
    }

    .second-provocation-container {
      padding-top: 20px;
    }

    .provocation-container {
      margin: 0;
      text-align: -webkit-center;
    }

    .provocation-hexagon {
      margin-bottom: 0;
    }

    .section-intro {
      column-count: 1;
    }
  }
}

@media screen and (max-width: 1300px) {
  .section .top-content-container {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .section .top-content-container {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .stat-background {
    top: -76px;
  }
}