@import 'shared';

.hex-overlay-container {
  width: 100px;
  position: absolute;
  z-index: 100;
  top: -56px;
  display: flex;
  align-items: center;

  .hex-title {
    text-align: center;
    color: white;
    position: relative;
    z-index: 20;
    @include title-8();
    margin-bottom: 5px;
  }

  .hex-copy {
    position: relative;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 20;
    color: white;
    @include title-6();
    text-align: center;
  }

  .hex-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  &.large {
    width: 225px;
    height: 260px;
    top: -275px;
    right: -111px;

    .hex-title {
      font-family: 'Bungee', cursive;
      font-size: 36px;
      line-height: 40px;
    }
  }

  &.medium {
    width: 175px;
    height: 202px;
  }

  &.small {
    width: 134px;
    height: 154px;

    .hex-title {
      @include title-4();
    }
  }

  &.tiny {
    width: 100px;
    height: 115px;

    .hex-title {
      @include title-7();
    }
  }
}
