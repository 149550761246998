@import 'shared';

.provocation-hexagon {
  text-transform: uppercase;
  justify-content: center;
  margin-bottom: 50px;
  position: relative;
  width: 175px;
  height: 202px;
  // width: 153px;
  // height: 177px;  

  .provocation-hex-svg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  &:not(:first-child) {
    margin-top: 50px;
  }

  &:first-child {
    // margin-right: 20px;
  }

  .provocation-content {
    position: absolute;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 20;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;    
  }

  .provocation-title {
    @include title-4;
    margin-bottom: 0px;
  }
  
  .provocation-copy {
    font-family: 'IBM Plex Sans Condensed', sans-serif;
    font-size: 15px;
    line-height: 18px;
  }

  .cls-1 {
    fill:none;
    stroke:#A18B00;
    stroke-miterlimit:10;
    stroke-width:1.14px;
    stroke-dasharray:3.42 3.42;
  }
  .cls-2 {
    fill:#A18B00;
  }

}

.first-provocation-container .provocation-hexagon {
  margin-left: -30px;
}

.second-provocation-container .provocation-hexagon {
  margin-left: -125px;
}

.retirement {
  .provocation-content {
    color: map-get($retirementColors, dark);
  }
}

.home {
  .provocation-content {
    color: map-get($homeColors, dark);
  }
}

.school {
  .provocation-content {
    color: map-get($schoolColors, dark);
  }
}

.community {
  .provocation-content {
    color: map-get($communityColors, dark);
  }
}

.work {
  .provocation-content {
    color: map-get($workColors, dark);
  }
}

.care {
  .provocation-content {
    color: map-get($careColors, dark);
  }

}
.individual {
  .provocation-content {
    color: map-get($individualColors, dark);
  }
}