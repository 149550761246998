@import 'shared';

.donut-chart text:last-child {
  display: none;
}

.donut-chart {
  font-family: 'Bungee', cursive;
  font-size: 22px;
  line-height: 1.5em;
  max-width: 110px;
}

.stat-circle-container {
  max-width: 110px;
}