@import 'shared';

.grid {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  opacity: 0;
  z-index: 0;
}

@include respond-below(sm) {
  .grid-svg {
    width: 1000px;
  }
}
