@import 'shared';

.mobile-menu-modal {
  .modal-content {
    position: absolute;
    top: 0;
    border: none;
    border-radius: 0;
    width: 100%;
    margin: auto;
    background-color: transparent;
  }

  .close-icon {
    position: absolute;
    top: $standard-padding-2;
    right: $standard-padding-2;
    width: 50px;
    height: 50px;
    padding: 0 0 20px 20px;
    z-index: 10;
  }
}

.menu-container {
  text-align: right;
  color: map-get($grayColors, dark);
  text-transform: uppercase;
  background-color: transparent;
  font-family: 'IBM Plex Mono', italic;

  .menu-subtitle {
    display: none;
    text-transform: none;
    font-family: 'IBM Plex Sans', sans;
    margin-top: $standard-padding-2 * 2;
    margin-bottom: $standard-padding-2;
  }

  .menu-item,
  .intro-link,
  .about-link {
    transition: $transition;
  }

  .menu-item:hover, .menu-item.active,
  .intro-link:hover, .about-link:hover {
    transition: $transition;
    font-family: 'Bungee';
    cursor: pointer;
  }

  .menu-item-home:hover, .menu-item-home.active {
    color: map-get($homeColors, main);
  }

  .menu-item-community:hover, .menu-item-community.active {
    color: map-get($communityColors, main);
  }

  .menu-item-work:hover, .menu-item-work.active {
    color: map-get($workColors, main);
  }

  .menu-item-school:hover, .menu-item-school.active {
    color: map-get($schoolColors, main);
  }

  .menu-item-care:hover, .menu-item-care.active {
    color: map-get($careColors, main);
  }

  .menu-item-retirement:hover, .menu-item-retirement.active {
    color: map-get($retirementColors, main);
  }

  .menu-item-individual:hover, .menu-item-individual.active {
    color: map-get($individualColors, main);
  }

  .white-space {
    height: 2em;
    width: 100%;
  }

  .menu-content {
    margin-top: 100px;
    font-size: 16px;
    line-height: 2em;
    margin-bottom: $standard-padding-2;
  }

  .share-links {
    cursor: pointer;
    right: $standard-padding-2;
  }

  .share-link {
    margin-bottom: $standard-padding-2 * 2;
    float: right;
    margin-left: $standard-padding-2;
  }

  .copyright {
    clear: both;
    text-transform: capitalize;
    font-family: 'IBM Plex Sans', sans;
    opacity: .5;
    margin-bottom: $standard-padding-2;
  }

  @media (min-width: $breakpoint) {
    display: none;
  }
}
