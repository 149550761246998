@import 'shared';

.home .lottie-hotspot {
  cursor: pointer;
}

.section {
  font-family: 'IBM Plex Sans', sans;
  font-size: 16px;
  line-height: 1.5em;
  color: #4a4a4a;
  position: absolute;
  padding-bottom: 100px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1366px;
  width: 100%;
  transition: all 1s ease;
  z-index: 30;
  background-image: linear-gradient(to bottom, rgba(255,245,217, 0.0) 2%, rgba(255,245,217,1));

  .lottie-hotspot {
    height: 400px;
  }

  .section-intro-container {
    padding: 0;
    align-items: center;

    .intro-row {
      align-items: center;
    }
  }
}

.next-button {
  @include button-default();
  width: 300px;
  margin: $standard-margin*2 auto 0 auto;
  display: block;

  &:hover {
    text-decoration: none;
  }
}

.section-on .section {
  opacity: 1;
}

.section-off .section {
  opacity: 0;
}

.quotes-and-stats {
  @include stats-copy();
}

.home .quotes-and-stats a:hover {
  color: map-get($homeColors, neon);
}

.care .quotes-and-stats a:hover {
  color: map-get($careColors, neon);
}

.retirement .quotes-and-stats a:hover {
  color: map-get($retirementColors, neon);
}

.school .quotes-and-stats a:hover {
  color: map-get($schoolColors, neon);
}

.work .quotes-and-stats a:hover {
  color: map-get($workColors, neon);
}

.community .quotes-and-stats a:hover {
  color: map-get($communityColors, neon);
}

.individual .quotes-and-stats a:hover {
  color: map-get($individualColors, neon);
}

.section-title {
  margin-top: 20px;
}

.section-title,
.content-title {
  @include title-1();
}

.home .section-title {
  color: map-get($homeColors, dark);
}

.retirement .section-title {
  color: map-get($retirementColors, dark);
}

.individual .section-title {
  color: map-get($individualColors, dark);
}

.school .section-title {
  color: map-get($schoolColors, dark);
}

.care .section-title {
  color: map-get($careColors, dark);
}

.work .section-title {
  color: map-get($workColors, dark);
}

.community .section-title {
  color: map-get($communityColors, dark);
}

.section-title,
.section-provocation:not(:last-child):not(:last-child),
.section-statistic  {
  margin-bottom: 25px;
}

.section-provocation {
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
}

.provocations-reveal {
  opacity: 0;
}

.attribution {
  margin-top: 5px;
  display: block;
  color: #4a4a4a;
  text-decoration: underline;
  transition: all 0.5s ease;

  &:hover {
    transition: all 0.5s ease;
  }
}

.first-provocation-container {
  // padding-top: 80px;
  margin-left: -50px;
  margin-right: 50px;
}

.second-provocation-container {
  padding-top: 110px;
}

.section-provocations,
.build-item-content,
.hear-item-content {
  background-color: #328fce;
  color: white;
  padding: 50px;
}

.build-item-img {
  width: 100%;
  height: auto;
}

.build-item-img-container {
  text-align: center;
}

.section .watch-item-img-container {
  // padding-left: 0;
  // padding-right: 0;
  text-align: center;
  margin-bottom: 15px;
}

.build-item-copy {
  @include stats-copy();
}

.section-column {
  // border: 1px dashed blue;
}

.build-this-row {
  margin-top: 450px;
}

.build-this-content-col {
  position: relative;
}

.build-this-content-row {
  position: relative;
  margin-top: 94px;

  .build-this-subtitle {
    position: absolute;
    top: -70px;
    left: 0px;
    width: 200px;
    font-family: 'IBM Plex Mono', mono;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    z-index: 50;
    color: $section-dark-text-color;
  }
}

.watch-this-content-row {
  position: relative;
  margin-top: 240px;

  .watch-this-subtitle {
    position: absolute;
    top: -55px;
    left: 0px;
    width: 180px;
    font-family: 'IBM Plex Mono', mono;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    z-index: 50;
    color: $section-dark-text-color;
  }

  .hex-container {
    position: absolute;
    top: -170px;
    right: -65px;
  }
}

.build-item-line {
  border-right: 2px solid #328fce;
  position: absolute;
  right: 0;
  height: 100px;
  top: -50px;
  display: none;
}

.watch-item-line {
  border-right: 2px solid #328fce;
  position: absolute;
  right: 0;
  height: 100px;
  top: -50px;
  z-index: 10;
}

.top-content-container-container,
.build-this-container-container,
.hear-this-container-container {
  opacity: 0;

  .hex-svg {
    opacity: .8;
  }
}

.section .top-content-container {
  padding: 20px 0;
  position: relative;
  margin-bottom: 100px;
  margin-top: 180px;
  margin-left: 0;
  margin-right: 0;
  // background: rgba(255,255,255,0.5);

  .todays-subtitle {
    position: absolute;
    top: -100px;
    left: 140px;
    width: 120px;
    font-family: 'IBM Plex Mono', mono;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    z-index: 50;
    color: $section-dark-text-color;
  }

  .hex-container {
    top: -130px;
    left: 20px;
  }
}

.hear-this-section {
  padding-bottom: 35px;
  padding-top: 50px;
  position: relative;
  margin-top: -200px;
  margin-bottom: 100px;

  .hear-this-subtitle {
    position: absolute;
    top: -40px;
    left: 110px;
    width: 120px;
    font-family: 'IBM Plex Mono', mono;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    z-index: 50;
    color: $section-dark-text-color;
  }

  .hex-container {
    position: absolute;
    top: -165px;
    left: -102px;
  }

  .hear-this-line {
    border-left: 2px solid #328fce;
    position: absolute;
    left: 0;
    height: 100px;
    top: -50px;
    display: none;
  }
}

.section .stat-item {
  margin-bottom: 20px;

  div {
    align-items: center;
  }

  .quotes-and-stats {
    padding-left: 15px;
  }
}

.section-link {
  text-decoration: underline;
  transition: all 0.5s ease;
  color: map-get($grayColors, dark);

  &:hover {
    transition: all 0.5s ease;
    font-style: bold;
  }
}

.retirement {
  .section-link {
    &:hover {
      color: map-get($retirementColors, neon);
    }
  }
}

.home {
  .section-link {
    &:hover {
      color: map-get($homeColors, neon);
    }
  }
}

.care {
  .section-link {
    &:hover {
      color: map-get($careColors, neon);
    }
  }
}

.community {
  .section-link {
    &:hover {
      color: map-get($communityColors, neon);
    }
  }
}

.school {
  .section-link {
    &:hover {
      color: map-get($schoolColors, neon);
    }
  }
}

.work {
  .section-link {
    &:hover {
      color: map-get($workColors, neon);
    }
  }
}

.home .statistics-container {
  border-color: map-get($homeColors, neon);
}

.individual .statistics-container {
  border-color: map-get($individualColors, neon);
}

.retirement .statistics-container {
  border-color: map-get($retirementColors, neon);
}

.hear-item-img-container {
  border-left: 2px solid #328fce;
}

.build-item-content,
.hear-item-content,
.hear-item-img-container {
  padding: 25px;
}

.section-intro-container,
.statistics-container {
  color: #4a4a4a;
}

.stat-background {
  width: 100%;
  position: absolute;
  top: -80px;
}



.stat-background-bottom {
  width: 100%;
  position: absolute;
}

.todays-reality-content-container {
  border-right: 1px solid black;
  border-left: 1px solid black;
  padding: 0 15px;
}

.home .todays-reality-content-container {
  border-right: 1px solid map-get($homeColors, dark);
  border-left: 1px solid map-get($homeColors, dark);
}

.retirement .todays-reality-content-container {
  border-right: 1px solid map-get($retirementColors, dark);
  border-left: 1px solid map-get($retirementColors, dark);
}

.school .todays-reality-content-container {
  border-right: 1px solid map-get($schoolColors, dark);
  border-left: 1px solid map-get($schoolColors, dark);
}

.work .todays-reality-content-container {
  border-right: 1px solid map-get($workColors, dark);
  border-left: 1px solid map-get($workColors, dark);
}

.individual .todays-reality-content-container {
  border-right: 1px solid map-get($individualColors, dark);
  border-left: 1px solid map-get($individualColors, dark);
}

.care .todays-reality-content-container {
  border-right: 1px solid map-get($careColors, dark);
  border-left: 1px solid map-get($careColors, dark);
}

.community .todays-reality-content-container {
  border-right: 1px solid map-get($communityColors, dark);
  border-left: 1px solid map-get($communityColors, dark);
}

.stat-circle-container {
  display: inline-block;
  position: relative;
  width: 100%;
}

.stat-circle-helper {
  margin-top: 100%;
}

.stat-circle {

  text-align: center;
  border-radius: 100%;
  background-color: black;
  color: white;
  font-family: 'Bungee', cursive;
  font-size: 18px;
  line-height: .8em;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: silver; 
  
}

.stat-unit {
  font-size: 10px;
}

.home .stat-circle {
  background-color: map-get($homeColors, dark);
}

.school .stat-circle {
  background-color: map-get($schoolColors, dark);
}

.retirement .stat-circle {
  background-color: map-get($retirementColors, dark);
}

.care .stat-circle {
  background-color: map-get($careColors, dark);
}

.individual .stat-circle {
  background-color: map-get($schoolColors, dark);
}

.community .stat-circle {
  background-color: map-get($communityColors, dark);
}

.work .stat-circle {
  background-color: map-get($workColors, dark);
}

.individual .stat-circle {
  background-color: map-get($individualColors, dark);
}

.section-intro {
  column-count: 2;
}

.section-content-container {
  margin-top: 100px;
}

.watch-item-title {
  @include title-small();
  margin-bottom: 10px;
}

.watch-item-content {
  @include content-small();
}

.statistic-value-unit {
  font-family: 'IBM Plex Mono', mono;
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.statistic-clear {
  clear: both;
}

.build-this-badge,
.hear-this-badge,
.watch-this-badge {
  width: 100%;
}

.build-this-badge,
.watch-this-badge {
  margin-top: 100px;
}

.build-row {
  margin-bottom: 20px;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.section .watch-this-content-col {
  padding-top: 15px;
}

.retirement {
  .build-row, .hear-this-container, .watch-this-content-col {
    background-color: rgba(115, 86, 199, 0.8);
  }
}

.home {
  .build-row, .hear-this-container, .watch-this-content-col {
    background-color: rgba(43, 125, 191, 0.8);
  }
}

.care {
  .build-row, .hear-this-container, .watch-this-content-col {
    background-color: rgba(240, 83, 117, 0.8);
  }
}

.work {
  .build-row, .hear-this-container, .watch-this-content-col {
    background-color: rgba(244, 125, 32, 0.8);
  }
}

.community {
  .build-row, .hear-this-container, .watch-this-content-col {
    background-color: rgba(189, 166, 25, 0.8);
  }
}

.individual {
  .build-row, .hear-this-container, .watch-this-content-col {
    background-color: rgba(0, 149, 152, 0.8);
  }
}

.school {
  .build-row, .hear-this-container, .watch-this-content-col {
    background-color: rgba(0, 149, 75, 0.8);
  }
}

.section .watch-row {
  margin-left: 0px;
  margin-right: 0px;
  color: white;
  padding-top: 15px;
  padding-bottom: 20px;
  position: relative;
  z-index: 20;
}

.section .watch-row-border {
  margin-top: 30px;
  border: 0px;
  border-bottom: 1px solid white;
  border-style: dashed;
}

.section .watch-row:last-child {
  border-bottom: 0px;
}

.hear-this-badge {
  margin-top: 550px;
}

.section .hear-this-container {
  margin-bottom: 20px;
  color: white;
  padding: 20px 20px 20px 10px;
}

.hear-this-box {
  padding: 20px;
  position: relative;
  color: white;
  padding-left: 60px;
}

.hear-item-icon {
  width: 50px;
  height: auto;
}

.hear-item-img {
  width: 100%;
  height: auto;
}

.hear-item-line {
  content: '';
  width: 100%;
  height: 100px;
  padding: 0;
  margin: 0;
  top: 0;
  right: 0;
}

.hear-item-line {
  border-left: 2px solid #328fce;
}

.hear-item-location {
  margin-left: 10px;
  @include stats-copy();
  display: none;
}

.build-item-img-container,
.hear-item-img-container,
.hear-item-icon-container,
.watch-item-img-container {
  width: 100%;
  text-align: center;
}

.hear-item-name {
  @include title-5();
  text-transform: capitalize;
  margin-bottom: 0px;
}

.hear-item-quote {
  border: 0px;
  border-top: 1px solid white;
  border-style: dashed;
  @include stats-copy();
  padding-top: 10px;
}

.build-item-title {
  @include title-5();
  line-height: 1.2em;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.hear-item-name {
  margin-bottom: 10px;
}

.hear-item-context {
  font-style: italic;
  @include stats-copy();
  margin-bottom: 10px;
  margin-top: -10px;
}

@import '_section-responsive';
