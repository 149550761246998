@import 'shared';

.landscape-svg {
  .cls-1,
  .cls-13,
  .cls-20,
  .cls-25,
  .cls-3,
  .cls-32 {
    fill: none;
  }

  .cls-2 {
    clip-path: url(#clip-path);
  }

  .cls-3 {
    stroke: #f15a22;
  }

  .cls-13,
  .cls-19,
  .cls-20,
  .cls-25,
  .cls-3,
  .cls-30,
  .cls-32,
  .cls-49,
  .cls-50,
  .cls-51,
  .cls-53 {
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .cls-13,
  .cls-20,
  .cls-25,
  .cls-3,
  .cls-32 {
    fill-rule: evenodd;
  }

  .cls-4 {
    fill: #f15a22;
  }

  .cls-5 {
    fill: #fed07f;
  }

  .cls-6 {
    fill: #fcee21;
  }

  .cls-7 {
    fill: #fbb163;
  }

  .cls-8 {
    fill: #d4d4d5;
  }

  .cls-19,
  .cls-31,
  .cls-49,
  .cls-50,
  .cls-51,
  .cls-52,
  .cls-53,
  .cls-9 {
    fill: #4a4a4a;
  }

  .cls-10 {
    fill: #4b4b4b;
  }

  .cls-11 {
    opacity: 0;
  }

  .cls-12,
  .cls-43 {
    fill: #fff;
  }

  .cls-13 {
    stroke: #2cb34b;
  }

  .cls-14 {
    fill: #2cb34b;
  }

  .cls-15 {
    fill: #008343;
  }

  .cls-16 {
    fill: #82c562;
  }

  .cls-17 {
    fill: lime;
  }

  .cls-18 {
    fill: #58ba5e;
  }

  .cls-19,
  .cls-31,
  .cls-52,
  .cls-53 {
    stroke: #4a4a4a;
  }

  .cls-19 {
    stroke-width: 1.65px;
  }

  .cls-20 {
    stroke: #9f87e8;
  }

  .cls-21 {
    fill: #9f87e8;
  }

  .cls-22 {
    fill: #c8b5ff;
  }

  .cls-23 {
    fill: #f0f;
  }

  .cls-24 {
    fill: #57448d;
  }

  .cls-25 {
    stroke: #ed175e;
  }

  .cls-26,
  .cls-30 {
    fill: #ed175e;
  }

  .cls-27 {
    fill: #f7b0c4;
  }

  .cls-28 {
    fill: #ffe6ec;
  }

  .cls-29 {
    fill: #f48fa8;
  }

  .cls-30 {
    stroke: #ffe6ec;
    stroke-width: 0.5px;
  }

  .cls-31,
  .cls-43,
  .cls-52 {
    stroke-miterlimit: 10;
  }

  .cls-32 {
    stroke: #328fce;
  }

  .cls-33 {
    fill: #328fce;
  }

  .cls-34 {
    fill: #0060a0;
  }

  .cls-35 {
    fill: #93c1e7;
  }

  .cls-36 {
    fill: aqua;
  }

  .cls-37 {
    fill: #66acde;
  }

  .cls-38 {
    fill: #f05375;
  }

  .cls-39 {
    fill: #e0c200;
  }

  .cls-40 {
    fill: #edd535;
  }

  .cls-41 {
    fill: #ff0;
  }

  .cls-42 {
    fill: #ffe954;
  }

  .cls-43 {
    stroke: #fff;
  }

  .cls-44 {
    opacity: 0.5;
  }

  .cls-45 {
    opacity: 0.33;
  }

  .cls-46 {
    opacity: 0.25;
  }

  .cls-47 {
    fill: #a18b00;
  }

  .cls-48 {
    opacity: 0.75;
  }

  .cls-49,
  .cls-50,
  .cls-51 {
    stroke: #4b4b4b;
  }

  .cls-49 {
    stroke-width: 0.05px;
  }

  .cls-50 {
    stroke-width: 0.13px;
  }

  .cls-51 {
    stroke-width: 0.3px;
  }

  .cls-52 {
    stroke-width: 0.58px;
  }

  .cls-53 {
    stroke-width: 0.8px;
  }
}

.portrait-svg {
.cls-1,
.cls-3,
.cls-31,
.cls-36,
.cls-42,
.cls-47 {
  fill: none;
}

.cls-2 {
  clip-path: url(#clip-path);
}

.cls-3,
.cls-8 {
  stroke: #ed175e;
}

.cls-26,
.cls-27,
.cls-28,
.cls-29,
.cls-3,
.cls-31,
.cls-36,
.cls-42,
.cls-47 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-12,
.cls-29,
.cls-3,
.cls-31,
.cls-36,
.cls-42,
.cls-47 {
  stroke-width: 0.5px;
}

.cls-3,
.cls-31,
.cls-36,
.cls-42,
.cls-47 {
  fill-rule: evenodd;
}

.cls-4 {
  fill: #ed175e;
}

.cls-5 {
  fill: #f7b0c4;
}

.cls-6 {
  fill: #ffe6ec;
}

.cls-7 {
  fill: #f48fa8;
}

.cls-8 {
  fill: #f16f8e;
  stroke-width: 0.31px;
}

.cls-12,
.cls-19,
.cls-21,
.cls-8 {
  stroke-miterlimit: 10;
}

.cls-9 {
  fill: #d4d4d5;
}

.cls-10,
.cls-12,
.cls-26,
.cls-27,
.cls-28,
.cls-29 {
  fill: #4a4a4a;
}

.cls-11 {
  fill: #4b4b4b;
}

.cls-12,
.cls-29 {
  stroke: #4a4a4a;
}

.cls-13,
.cls-20,
.cls-21 {
  fill: #fff;
}

.cls-13 {
  opacity: 0;
}

.cls-14 {
  fill: #e0c200;
}

.cls-15 {
  fill: #edd535;
}

.cls-16,
.cls-19 {
  fill: #ff0;
}

.cls-17 {
  fill: #fcee21;
}

.cls-18 {
  fill: #ffe954;
}

.cls-19 {
  stroke: #ff0;
  stroke-width: 0.25px;
}

.cls-21 {
  stroke: #fff;
}

.cls-22 {
  opacity: 0.5;
}

.cls-23 {
  opacity: 0.33;
}

.cls-24 {
  opacity: 0.25;
}

.cls-25 {
  opacity: 0.75;
}

.cls-26,
.cls-27,
.cls-28 {
  stroke: #4b4b4b;
}

.cls-26 {
  stroke-width: 0.05px;
}

.cls-27 {
  stroke-width: 0.13px;
}

.cls-28 {
  stroke-width: 0.3px;
}

.cls-30 {
  fill: #a18b00;
}

.cls-31 {
  stroke: #f47d20;
}

.cls-32 {
  fill: #f47d20;
}

.cls-33 {
  fill: #fed07f;
}

.cls-34 {
  fill: #fbb163;
}

.cls-35 {
  fill: #f15a22;
}

.cls-36 {
  stroke: #2cb34b;
}

.cls-37 {
  fill: #008343;
}

.cls-38 {
  fill: #82c562;
}

.cls-39 {
  fill: lime;
}

.cls-40 {
  fill: #58ba5e;
}

.cls-41 {
  fill: #2cb34b;
}

.cls-42 {
  stroke: #9f87e8;
}

.cls-43 {
  fill: #c8b5ff;
}

.cls-44 {
  fill: #f0f;
}

.cls-45 {
  fill: #57448d;
}

.cls-46 {
  fill: #9f87e8;
}

.cls-47 {
  stroke: #328fce;
}

.cls-48 {
  fill: #0060a0;
}

.cls-49 {
  fill: #93c1e7;
}

.cls-50 {
  fill: aqua;
}

.cls-51 {
  fill: #66acde;
}

.cls-52 {
  fill: #328fce;
}  
}

@mixin hide() {
  opacity: 0;
  transition: $transition;
}

@mixin show() {
  opacity: 1;
  transition: $transition;
}

#work_connection, #home_connection, #care_connection, #school_connection, #retirement_connection,
#work_dot, #home_dot, #care_dot, #school_dot, #retirement_dot {
  opacity: 1;
  transition: $transition;
}

.section-on {
  .maxi-map {
    #work_connection, #home_connection, #care_connection, #school_connection, #retirement_connection,
    #work_dot, #home_dot, #care_dot, #school_dot, #retirement_dot {
      opacity: .25;
      transition: $transition;
    }
  }
}

.landscape, .portrait {
  &.map {
    &.show-home-visited {
      #home_title {
        opacity: 0;
        transition: $transition;
      }
    }
    
    &.show-care-visited {
      #care_title {
        opacity: 0;
        transition: $transition;
      }
    }
  
    &.show-work-visited {
      #work_title {
        opacity: 0;
        transition: $transition;
      }
    }
  
    &.show-school-visited {
      #school_title {
        opacity: 0;
        transition: $transition;
      }
    }
  
    &.show-community-visited {
      #community_title {
        opacity: 0;
        transition: $transition;
      }
    }
  
    &.show-retirement-visited {
      #retirement_title {
        opacity: 0;
        transition: $transition;
      }
    }
  }
}

.maxi-map {
  .category {
    transition: $transition;
  }

  .hide-svg {
    opacity: 0;
    transition: $transition;
  }
}

#home {
  cursor: pointer;

  #home_gray {
    @include show();
  }

  #home_color {
    @include hide();
  }

  &:hover, &.color {
    #home_gray {
      @include hide();
    }

    #home_color {
      @include show();
    }
  }
}

@keyframes animateDoor {
  from {
    fill: map-get($homeColors, dark);
  }

  to {
    fill: map-get($careColors, main);
  }
}

#visit_title {
  opacity: 0;
  transition: $transition;
}

.home .maxi-map {
  #home_door polygon {
    fill: map-get($careColors, main);
    animation-name: '';
  }

  #visit_title {
    opacity: 1;
    transition: $transition;
  }

  #home_door path {
    fill: map-get($careColors, neon);
    transition: $transition;
  }

  .animate-door {
      #home_door polygon {
      animation-duration: 1s;
      animation-name: animateDoor;
      animation-iteration-count: infinite;
    }

    #visit_title {
      opacity: .75;
      transition: $transition;
    }

    #home_door path {
      fill: map-get($homeColors, dark);
      transition: $transition;
    }
  }
}

#work {
  cursor: pointer;

  #work_gray{
    @include show();
  }

  #work_color, .color {
    @include hide();
  }

  &:hover, &.color {
    #work_gray {
      @include hide();
    }

    #work_color {
      @include show();
    }
  }
}

#school {
  cursor: pointer;

  #school_gray {
    @include show();
  }

  #school_color {
    @include hide();
  }

  &:hover, &.color {
    #school_gray {
      @include hide();
    }

    #school_color {
      @include show();
    }
  }
}

#community {
  cursor: pointer;

  #community_gray {
    @include show();
  }

  #community_color {
    @include hide();
  }

  &:hover, &.color {
    #community_gray {
      @include hide();
    }

    #community_color {
      @include show();
    }
  }
}

#care {
  cursor: pointer;

  #care_gray {
    @include show();
  }

  #care_color {
    @include hide();
  }

  &:hover, &.color {
    #care_gray {
      @include hide();
    }

    #care_color {
      @include show();
    }
  }
}

#retirement {
  cursor: pointer;

  #retirement_gray {
    @include show();
  }

  #retirement_color {
    @include hide();
  }

  &:hover, &.color {
    #retirement_gray {
      @include hide();
    }

    #retirement_color {
      @include show();
    }
  }
}

.mini-map .map-overlay {
  display: none !important;
}

.map-overlay {
  pointer-events: none;
  padding: 100px;
  z-index: 10;

  .hex-container .hex-title {
    font-family: 'IBM Plex Sans', mono;
    font-weight: bolder;
    font-size: 16px;
    line-height: 1.3em;
  }

  &.homeOverlay {
    margin-top: 63px;
    margin-left: 33px;
  }

  &.schoolOverlay {
    margin-top: 144px;
    margin-left: -10px;
  }

  &.workOverlay {
    margin-top: -187px;
    margin-left: -57px;
  }

  &.careOverlay {
    padding: 200px;
    margin-left: -66px;
    margin-top: 479px;
  }

  &.retirementOverlay {
    margin-top: 277px;
    margin-left: -337px;
  }

  &.communityOverlay {
    margin-top: 200px;
    margin-left: -188px;
  }
}
