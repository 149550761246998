@import 'shared';

.tooltips-container {
  position: absolute;
  z-index: 50;
}

.care-site-tooltip {
  transition: all 0.5s ease;
  opacity: 0;
  position: absolute;
  pointer-events: none;

  &.show-me {
    opacity: 1;
  }
}

.school-tooltip {
  top: 273px;
  left: 120px;
}

.retirement-tooltip {
  top: 568px;
  left: 320px;
}

.work-tooltip {
  // top: 25vh;
  // left: 70vw;
  top: 203px;
  left: 1064px;  
}

.home-tooltip {
  top: 545px;
  left: 530px;
}

.community-tooltip {
  // top: 13vh;
  // left: 41vw;
  top: 142px;
  left: 594px;
}

.care-tooltip {
  top: 491px;
  left: 1050px;
}



@media screen and (max-width: 1366px) {
  .care-tooltip {
    top: 300px;
    left: 70vw;
  }

  .work-tooltip {
    top: 110px;
    left: 77vw;
  }

  .community-tooltip {
    top: 100px;
    left: 42vw;
  }

  .retirement-tooltip {
    top: 400px;
    left: 20vw;
  }

  .home-tooltip {
    top: 400px;
    left: 40vw;
  }
}

// Example usage:
@include respond-below(sm) {
  .tooltips-container {
    display: none;
  }
}