@import url(https://fonts.googleapis.com/css?family=Bungee|IBM+Plex+Mono|IBM+Plex+Sans|IBM+Plex+Sans+Condensed);
@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

html {
  touch-action: manipulation; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.care-body {
  background-color: #fff5d9; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.modal {
  overflow-y: scroll;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch; }

.care-body .modal-backdrop {
  background-color: #fff5d9; }
  .care-body .modal-backdrop.show {
    opacity: .75; }

.care-intro-modal.modal-lg {
  max-width: 1200px; }

.care-intro-modal .modal-content {
  border: none;
  border-radius: 0;
  background-color: transparent;
  width: 100%;
  margin: auto; }

.care-intro-modal .hex-group {
  width: 100%;
  margin: auto; }

.care-intro-modal .hex-background {
  position: absolute;
  width: 100%;
  z-index: -1; }

.care-intro-modal .hex-container {
  top: auto;
  left: auto;
  cursor: pointer; }
  .care-intro-modal .hex-container .hex-title {
    font-family: 'Bungee', cursive;
    font-size: 24px;
    line-height: 1.5em;
    line-height: 1em; }

.care-intro-modal .hex-one .hex-container {
  margin-top: -50px;
  margin-left: -87.5px;
  left: 0; }

.care-intro-modal .hex-two .hex-container {
  margin: auto;
  margin-top: 20px;
  position: relative; }
  .care-intro-modal .hex-two .hex-container .hex-title {
    color: #7f7f7f;
    width: 75%; }
  .care-intro-modal .hex-two .hex-container .hex-svg {
    opacity: .1; }

.care-intro-modal .care-intro-video,
.care-intro-modal .care-intro-content {
  padding: 50px;
  text-align: center;
  width: 100%; }
  .care-intro-modal .care-intro-video .intro-title,
  .care-intro-modal .care-intro-content .intro-title {
    font-family: 'IBM Plex Mono', bold;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.3em;
    width: 40%;
    margin: auto;
    margin-bottom: 20px; }
    .care-intro-modal .care-intro-video .intro-title .italic,
    .care-intro-modal .care-intro-content .intro-title .italic {
      font-style: italic; }
  .care-intro-modal .care-intro-video .intro-video,
  .care-intro-modal .care-intro-content .intro-video {
    width: 100%;
    margin: auto;
    margin-top: 120px; }
  .care-intro-modal .care-intro-video .intro-copy,
  .care-intro-modal .care-intro-content .intro-copy {
    font-family: 'IBM Plex Mono', mono;
    font-size: 12px;
    line-height: 1.5em;
    font-size: 12px;
    text-align: left;
    margin-bottom: 20px; }

.care-intro-modal .care-intro-video .intro-title {
  margin-top: 100px; }

.care-intro-modal .care-intro-content {
  background-color: rgba(255, 245, 217, 0.5);
  padding: 30px;
  border: 1px solid #4a4a4a;
  border-style: dashed; }
  .care-intro-modal .care-intro-content .intro-title {
    width: 100%;
    text-align: left; }

.care-intro-modal .intro-button {
  cursor: pointer;
  color: #fff5d9;
  font-family: 'Bungee', cursive;
  font-size: 20px;
  line-height: 1.5em;
  line-height: 1.2em;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: black;
  transition: all 0.5s ease;
  border: 1px solid transparent;
  margin: 0;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px; }
  .care-intro-modal .intro-button:hover {
    background-color: #fff5d9;
    background-color: transparent;
    color: black;
    transition: all 0.5s ease;
    border: 1px solid black; }

@media screen and (max-width: 1200px) {
  .care-intro-modal.modal-lg {
    max-width: 500px; }
  .care-intro-modal .hex-background {
    top: 70px; }
  .care-intro-modal .hex-one .hex-container {
    margin: auto;
    position: relative; }
  .care-intro-modal .care-intro-video .intro-title {
    margin-top: 100px; }
  .care-intro-modal .care-intro-content {
    margin-bottom: 40px;
    margin-top: 200px; }
  .care-intro-modal .hex-two .hex-container {
    width: 100px;
    height: 115px; }
    .care-intro-modal .hex-two .hex-container .hex-title {
      font-size: 14px; }
  .care-intro-modal .intro-button {
    width: 65%; } }

@media screen and (max-width: 576px) {
  .care-intro-modal .modal-content {
    border: 1px solid #4a4a4a;
    border-style: dashed; }
  .care-intro-modal .hex-background {
    display: none; }
  .care-intro-modal .care-intro-video {
    padding: 20px;
    border: 0px solid #4a4a4a;
    border-style: dashed; }
    .care-intro-modal .care-intro-video .intro-title {
      width: 100%;
      margin-top: 40px;
      text-align: left; }
    .care-intro-modal .care-intro-video .hex-two .hex-container {
      display: none; }
  .care-intro-modal .care-intro-content {
    margin-bottom: 0;
    padding: 30px;
    border: 0px solid #4a4a4a; }
    .care-intro-modal .care-intro-content .intro-button {
      cursor: pointer;
      position: relative;
      margin-top: 20px;
      margin-bottom: 0;
      width: 100%;
      margin-left: 0;
      padding-left: 25%;
      padding-right: 25%; } }

@media (max-width: 1199px) {
  .care-intro-modal .care-intro-video .intro-video {
    margin-top: 180px; } }

@media (max-width: 575px) {
  .care-intro-modal .care-intro-video .intro-video {
    margin-top: 40px; }
  .care-intro-modal .care-intro-content {
    margin-top: 0px; } }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.modal {
  background-color: rgba(255, 245, 217, 0.5); }

.care-about-modal {
  padding: 50px; }
  .care-about-modal.modal-lg {
    max-width: 1200px; }
  .care-about-modal .close-icon {
    width: 35px;
    position: absolute;
    cursor: pointer;
    top: 50px;
    right: 50px;
    opacity: .5;
    transition: all 0.5s ease;
    z-index: 10; }
  .care-about-modal .close-icon:hover {
    opacity: 1;
    transition: all 0.5s ease; }
  .care-about-modal .heart-icon {
    position: absolute;
    right: 50px;
    bottom: 50px;
    width: 50px;
    height: auto; }
  .care-about-modal .privacy-policy {
    margin-top: 60px;
    display: inline-block;
    background-color: #4D4D4D;
    color: #FFF6D9;
    padding: 3px 20px;
    font-family: 'IBM Plex Mono', mono;
    font-size: 12px;
    line-height: 1.5em;
    cursor: pointer;
    z-index: 100;
    transition: all 0.5s ease;
    border: 1px solid #4D4D4D; }
    .care-about-modal .privacy-policy:hover {
      text-decoration: none;
      background-color: #FFF6D9;
      color: #4D4D4D;
      border: 1px solid #4D4D4D; }
  .care-about-modal .peeps {
    width: 100%;
    height: auto; }
  .care-about-modal .modal-content {
    padding: 50px;
    border: 1px solid #4a4a4a;
    border-style: dashed;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    margin: auto;
    background-color: rgba(255, 245, 217, 0.75); }
  .care-about-modal .care-about-content {
    padding: 20px;
    text-align: left;
    width: 100%; }
    .care-about-modal .care-about-content .about-title {
      font-family: 'IBM Plex Mono', bold;
      font-weight: bold;
      font-size: 30px;
      line-height: 1.3em;
      color: #4a4a4a;
      font-size: 24px;
      text-align: left;
      font-weight: bold;
      text-transform: uppercase; }
    .care-about-modal .care-about-content .about-copy, .care-about-modal .care-about-content .about-partner {
      font-family: 'IBM Plex Mono', mono;
      font-size: 12px;
      line-height: 1.5em;
      color: #4a4a4a;
      font-size: 12px;
      text-align: left; }
    .care-about-modal .care-about-content .about-copy {
      margin-bottom: 20px; }
    .care-about-modal .care-about-content .about-partner {
      font-style: italic;
      margin-bottom: 0; }
    .care-about-modal .care-about-content .italic {
      font-style: italic; }
    .care-about-modal .care-about-content .bold {
      font-weight: bold; }

@media screen and (max-width: 576px) {
  .care-about-modal {
    padding: 0; }
    .care-about-modal .privacy-policy {
      bottom: 140px; }
    .care-about-modal .modal-content {
      padding: 20px 0; }
    .care-about-modal .close-icon {
      position: fixed;
      top: 20px;
      right: 20px;
      width: 50px;
      height: 50px;
      opacity: 1;
      padding: 10px;
      z-index: 10; } }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.mobile-menu-modal .modal-content {
  position: absolute;
  top: 0;
  border: none;
  border-radius: 0;
  width: 100%;
  margin: auto;
  background-color: transparent; }

.mobile-menu-modal .close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  padding: 0 0 20px 20px;
  z-index: 10; }

.menu-container {
  text-align: right;
  color: #4a4a4a;
  text-transform: uppercase;
  background-color: transparent;
  font-family: 'IBM Plex Mono', italic; }
  .menu-container .menu-subtitle {
    display: none;
    text-transform: none;
    font-family: 'IBM Plex Sans', sans;
    margin-top: 40px;
    margin-bottom: 20px; }
  .menu-container .menu-item,
  .menu-container .intro-link,
  .menu-container .about-link {
    transition: all 0.5s ease; }
  .menu-container .menu-item:hover, .menu-container .menu-item.active,
  .menu-container .intro-link:hover, .menu-container .about-link:hover {
    transition: all 0.5s ease;
    font-family: 'Bungee';
    cursor: pointer; }
  .menu-container .menu-item-home:hover, .menu-container .menu-item-home.active {
    color: #328fce; }
  .menu-container .menu-item-community:hover, .menu-container .menu-item-community.active {
    color: #e0c200; }
  .menu-container .menu-item-work:hover, .menu-container .menu-item-work.active {
    color: #fbb163; }
  .menu-container .menu-item-school:hover, .menu-container .menu-item-school.active {
    color: #2cb34b; }
  .menu-container .menu-item-care:hover, .menu-container .menu-item-care.active {
    color: #f16f8e; }
  .menu-container .menu-item-retirement:hover, .menu-container .menu-item-retirement.active {
    color: #9f87e8; }
  .menu-container .menu-item-individual:hover, .menu-container .menu-item-individual.active {
    color: #00aaa7; }
  .menu-container .white-space {
    height: 2em;
    width: 100%; }
  .menu-container .menu-content {
    margin-top: 100px;
    font-size: 16px;
    line-height: 2em;
    margin-bottom: 20px; }
  .menu-container .share-links {
    cursor: pointer;
    right: 20px; }
  .menu-container .share-link {
    margin-bottom: 40px;
    float: right;
    margin-left: 20px; }
  .menu-container .copyright {
    clear: both;
    text-transform: capitalize;
    font-family: 'IBM Plex Sans', sans;
    opacity: .5;
    margin-bottom: 20px; }
  @media (min-width: 768px) {
    .menu-container {
      display: none; } }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.anim-ambient-container {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 18;
  pointer-events: none; }
  .anim-ambient-container.ambient-off {
    opacity: 0; }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.landscape-svg .cls-1,
.landscape-svg .cls-13,
.landscape-svg .cls-20,
.landscape-svg .cls-25,
.landscape-svg .cls-3,
.landscape-svg .cls-32 {
  fill: none; }

.landscape-svg .cls-2 {
  -webkit-clip-path: url(#clip-path);
          clip-path: url(#clip-path); }

.landscape-svg .cls-3 {
  stroke: #f15a22; }

.landscape-svg .cls-13,
.landscape-svg .cls-19,
.landscape-svg .cls-20,
.landscape-svg .cls-25,
.landscape-svg .cls-3,
.landscape-svg .cls-30,
.landscape-svg .cls-32,
.landscape-svg .cls-49,
.landscape-svg .cls-50,
.landscape-svg .cls-51,
.landscape-svg .cls-53 {
  stroke-linecap: round;
  stroke-linejoin: round; }

.landscape-svg .cls-13,
.landscape-svg .cls-20,
.landscape-svg .cls-25,
.landscape-svg .cls-3,
.landscape-svg .cls-32 {
  fill-rule: evenodd; }

.landscape-svg .cls-4 {
  fill: #f15a22; }

.landscape-svg .cls-5 {
  fill: #fed07f; }

.landscape-svg .cls-6 {
  fill: #fcee21; }

.landscape-svg .cls-7 {
  fill: #fbb163; }

.landscape-svg .cls-8 {
  fill: #d4d4d5; }

.landscape-svg .cls-19,
.landscape-svg .cls-31,
.landscape-svg .cls-49,
.landscape-svg .cls-50,
.landscape-svg .cls-51,
.landscape-svg .cls-52,
.landscape-svg .cls-53,
.landscape-svg .cls-9 {
  fill: #4a4a4a; }

.landscape-svg .cls-10 {
  fill: #4b4b4b; }

.landscape-svg .cls-11 {
  opacity: 0; }

.landscape-svg .cls-12,
.landscape-svg .cls-43 {
  fill: #fff; }

.landscape-svg .cls-13 {
  stroke: #2cb34b; }

.landscape-svg .cls-14 {
  fill: #2cb34b; }

.landscape-svg .cls-15 {
  fill: #008343; }

.landscape-svg .cls-16 {
  fill: #82c562; }

.landscape-svg .cls-17 {
  fill: lime; }

.landscape-svg .cls-18 {
  fill: #58ba5e; }

.landscape-svg .cls-19,
.landscape-svg .cls-31,
.landscape-svg .cls-52,
.landscape-svg .cls-53 {
  stroke: #4a4a4a; }

.landscape-svg .cls-19 {
  stroke-width: 1.65px; }

.landscape-svg .cls-20 {
  stroke: #9f87e8; }

.landscape-svg .cls-21 {
  fill: #9f87e8; }

.landscape-svg .cls-22 {
  fill: #c8b5ff; }

.landscape-svg .cls-23 {
  fill: #f0f; }

.landscape-svg .cls-24 {
  fill: #57448d; }

.landscape-svg .cls-25 {
  stroke: #ed175e; }

.landscape-svg .cls-26,
.landscape-svg .cls-30 {
  fill: #ed175e; }

.landscape-svg .cls-27 {
  fill: #f7b0c4; }

.landscape-svg .cls-28 {
  fill: #ffe6ec; }

.landscape-svg .cls-29 {
  fill: #f48fa8; }

.landscape-svg .cls-30 {
  stroke: #ffe6ec;
  stroke-width: 0.5px; }

.landscape-svg .cls-31,
.landscape-svg .cls-43,
.landscape-svg .cls-52 {
  stroke-miterlimit: 10; }

.landscape-svg .cls-32 {
  stroke: #328fce; }

.landscape-svg .cls-33 {
  fill: #328fce; }

.landscape-svg .cls-34 {
  fill: #0060a0; }

.landscape-svg .cls-35 {
  fill: #93c1e7; }

.landscape-svg .cls-36 {
  fill: aqua; }

.landscape-svg .cls-37 {
  fill: #66acde; }

.landscape-svg .cls-38 {
  fill: #f05375; }

.landscape-svg .cls-39 {
  fill: #e0c200; }

.landscape-svg .cls-40 {
  fill: #edd535; }

.landscape-svg .cls-41 {
  fill: #ff0; }

.landscape-svg .cls-42 {
  fill: #ffe954; }

.landscape-svg .cls-43 {
  stroke: #fff; }

.landscape-svg .cls-44 {
  opacity: 0.5; }

.landscape-svg .cls-45 {
  opacity: 0.33; }

.landscape-svg .cls-46 {
  opacity: 0.25; }

.landscape-svg .cls-47 {
  fill: #a18b00; }

.landscape-svg .cls-48 {
  opacity: 0.75; }

.landscape-svg .cls-49,
.landscape-svg .cls-50,
.landscape-svg .cls-51 {
  stroke: #4b4b4b; }

.landscape-svg .cls-49 {
  stroke-width: 0.05px; }

.landscape-svg .cls-50 {
  stroke-width: 0.13px; }

.landscape-svg .cls-51 {
  stroke-width: 0.3px; }

.landscape-svg .cls-52 {
  stroke-width: 0.58px; }

.landscape-svg .cls-53 {
  stroke-width: 0.8px; }

.portrait-svg .cls-1,
.portrait-svg .cls-3,
.portrait-svg .cls-31,
.portrait-svg .cls-36,
.portrait-svg .cls-42,
.portrait-svg .cls-47 {
  fill: none; }

.portrait-svg .cls-2 {
  -webkit-clip-path: url(#clip-path);
          clip-path: url(#clip-path); }

.portrait-svg .cls-3,
.portrait-svg .cls-8 {
  stroke: #ed175e; }

.portrait-svg .cls-26,
.portrait-svg .cls-27,
.portrait-svg .cls-28,
.portrait-svg .cls-29,
.portrait-svg .cls-3,
.portrait-svg .cls-31,
.portrait-svg .cls-36,
.portrait-svg .cls-42,
.portrait-svg .cls-47 {
  stroke-linecap: round;
  stroke-linejoin: round; }

.portrait-svg .cls-12,
.portrait-svg .cls-29,
.portrait-svg .cls-3,
.portrait-svg .cls-31,
.portrait-svg .cls-36,
.portrait-svg .cls-42,
.portrait-svg .cls-47 {
  stroke-width: 0.5px; }

.portrait-svg .cls-3,
.portrait-svg .cls-31,
.portrait-svg .cls-36,
.portrait-svg .cls-42,
.portrait-svg .cls-47 {
  fill-rule: evenodd; }

.portrait-svg .cls-4 {
  fill: #ed175e; }

.portrait-svg .cls-5 {
  fill: #f7b0c4; }

.portrait-svg .cls-6 {
  fill: #ffe6ec; }

.portrait-svg .cls-7 {
  fill: #f48fa8; }

.portrait-svg .cls-8 {
  fill: #f16f8e;
  stroke-width: 0.31px; }

.portrait-svg .cls-12,
.portrait-svg .cls-19,
.portrait-svg .cls-21,
.portrait-svg .cls-8 {
  stroke-miterlimit: 10; }

.portrait-svg .cls-9 {
  fill: #d4d4d5; }

.portrait-svg .cls-10,
.portrait-svg .cls-12,
.portrait-svg .cls-26,
.portrait-svg .cls-27,
.portrait-svg .cls-28,
.portrait-svg .cls-29 {
  fill: #4a4a4a; }

.portrait-svg .cls-11 {
  fill: #4b4b4b; }

.portrait-svg .cls-12,
.portrait-svg .cls-29 {
  stroke: #4a4a4a; }

.portrait-svg .cls-13,
.portrait-svg .cls-20,
.portrait-svg .cls-21 {
  fill: #fff; }

.portrait-svg .cls-13 {
  opacity: 0; }

.portrait-svg .cls-14 {
  fill: #e0c200; }

.portrait-svg .cls-15 {
  fill: #edd535; }

.portrait-svg .cls-16,
.portrait-svg .cls-19 {
  fill: #ff0; }

.portrait-svg .cls-17 {
  fill: #fcee21; }

.portrait-svg .cls-18 {
  fill: #ffe954; }

.portrait-svg .cls-19 {
  stroke: #ff0;
  stroke-width: 0.25px; }

.portrait-svg .cls-21 {
  stroke: #fff; }

.portrait-svg .cls-22 {
  opacity: 0.5; }

.portrait-svg .cls-23 {
  opacity: 0.33; }

.portrait-svg .cls-24 {
  opacity: 0.25; }

.portrait-svg .cls-25 {
  opacity: 0.75; }

.portrait-svg .cls-26,
.portrait-svg .cls-27,
.portrait-svg .cls-28 {
  stroke: #4b4b4b; }

.portrait-svg .cls-26 {
  stroke-width: 0.05px; }

.portrait-svg .cls-27 {
  stroke-width: 0.13px; }

.portrait-svg .cls-28 {
  stroke-width: 0.3px; }

.portrait-svg .cls-30 {
  fill: #a18b00; }

.portrait-svg .cls-31 {
  stroke: #f47d20; }

.portrait-svg .cls-32 {
  fill: #f47d20; }

.portrait-svg .cls-33 {
  fill: #fed07f; }

.portrait-svg .cls-34 {
  fill: #fbb163; }

.portrait-svg .cls-35 {
  fill: #f15a22; }

.portrait-svg .cls-36 {
  stroke: #2cb34b; }

.portrait-svg .cls-37 {
  fill: #008343; }

.portrait-svg .cls-38 {
  fill: #82c562; }

.portrait-svg .cls-39 {
  fill: lime; }

.portrait-svg .cls-40 {
  fill: #58ba5e; }

.portrait-svg .cls-41 {
  fill: #2cb34b; }

.portrait-svg .cls-42 {
  stroke: #9f87e8; }

.portrait-svg .cls-43 {
  fill: #c8b5ff; }

.portrait-svg .cls-44 {
  fill: #f0f; }

.portrait-svg .cls-45 {
  fill: #57448d; }

.portrait-svg .cls-46 {
  fill: #9f87e8; }

.portrait-svg .cls-47 {
  stroke: #328fce; }

.portrait-svg .cls-48 {
  fill: #0060a0; }

.portrait-svg .cls-49 {
  fill: #93c1e7; }

.portrait-svg .cls-50 {
  fill: aqua; }

.portrait-svg .cls-51 {
  fill: #66acde; }

.portrait-svg .cls-52 {
  fill: #328fce; }

#work_connection, #home_connection, #care_connection, #school_connection, #retirement_connection,
#work_dot, #home_dot, #care_dot, #school_dot, #retirement_dot {
  opacity: 1;
  transition: all 0.5s ease; }

.section-on .maxi-map #work_connection, .section-on .maxi-map #home_connection, .section-on .maxi-map #care_connection, .section-on .maxi-map #school_connection, .section-on .maxi-map #retirement_connection,
.section-on .maxi-map #work_dot, .section-on .maxi-map #home_dot, .section-on .maxi-map #care_dot, .section-on .maxi-map #school_dot, .section-on .maxi-map #retirement_dot {
  opacity: .25;
  transition: all 0.5s ease; }

.landscape.map.show-home-visited #home_title, .portrait.map.show-home-visited #home_title {
  opacity: 0;
  transition: all 0.5s ease; }

.landscape.map.show-care-visited #care_title, .portrait.map.show-care-visited #care_title {
  opacity: 0;
  transition: all 0.5s ease; }

.landscape.map.show-work-visited #work_title, .portrait.map.show-work-visited #work_title {
  opacity: 0;
  transition: all 0.5s ease; }

.landscape.map.show-school-visited #school_title, .portrait.map.show-school-visited #school_title {
  opacity: 0;
  transition: all 0.5s ease; }

.landscape.map.show-community-visited #community_title, .portrait.map.show-community-visited #community_title {
  opacity: 0;
  transition: all 0.5s ease; }

.landscape.map.show-retirement-visited #retirement_title, .portrait.map.show-retirement-visited #retirement_title {
  opacity: 0;
  transition: all 0.5s ease; }

.maxi-map .category {
  transition: all 0.5s ease; }

.maxi-map .hide-svg {
  opacity: 0;
  transition: all 0.5s ease; }

#home {
  cursor: pointer; }
  #home #home_gray {
    opacity: 1;
    transition: all 0.5s ease; }
  #home #home_color {
    opacity: 0;
    transition: all 0.5s ease; }
  #home:hover #home_gray, #home.color #home_gray {
    opacity: 0;
    transition: all 0.5s ease; }
  #home:hover #home_color, #home.color #home_color {
    opacity: 1;
    transition: all 0.5s ease; }

@-webkit-keyframes animateDoor {
  from {
    fill: #0060a0; }
  to {
    fill: #f16f8e; } }

@keyframes animateDoor {
  from {
    fill: #0060a0; }
  to {
    fill: #f16f8e; } }

#visit_title {
  opacity: 0;
  transition: all 0.5s ease; }

.home .maxi-map #home_door polygon {
  fill: #f16f8e;
  -webkit-animation-name: '';
          animation-name: ''; }

.home .maxi-map #visit_title {
  opacity: 1;
  transition: all 0.5s ease; }

.home .maxi-map #home_door path {
  fill: #ff459d;
  transition: all 0.5s ease; }

.home .maxi-map .animate-door #home_door polygon {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: animateDoor;
          animation-name: animateDoor;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.home .maxi-map .animate-door #visit_title {
  opacity: .75;
  transition: all 0.5s ease; }

.home .maxi-map .animate-door #home_door path {
  fill: #0060a0;
  transition: all 0.5s ease; }

#work {
  cursor: pointer; }
  #work #work_gray {
    opacity: 1;
    transition: all 0.5s ease; }
  #work #work_color, #work .color {
    opacity: 0;
    transition: all 0.5s ease; }
  #work:hover #work_gray, #work.color #work_gray {
    opacity: 0;
    transition: all 0.5s ease; }
  #work:hover #work_color, #work.color #work_color {
    opacity: 1;
    transition: all 0.5s ease; }

#school {
  cursor: pointer; }
  #school #school_gray {
    opacity: 1;
    transition: all 0.5s ease; }
  #school #school_color {
    opacity: 0;
    transition: all 0.5s ease; }
  #school:hover #school_gray, #school.color #school_gray {
    opacity: 0;
    transition: all 0.5s ease; }
  #school:hover #school_color, #school.color #school_color {
    opacity: 1;
    transition: all 0.5s ease; }

#community {
  cursor: pointer; }
  #community #community_gray {
    opacity: 1;
    transition: all 0.5s ease; }
  #community #community_color {
    opacity: 0;
    transition: all 0.5s ease; }
  #community:hover #community_gray, #community.color #community_gray {
    opacity: 0;
    transition: all 0.5s ease; }
  #community:hover #community_color, #community.color #community_color {
    opacity: 1;
    transition: all 0.5s ease; }

#care {
  cursor: pointer; }
  #care #care_gray {
    opacity: 1;
    transition: all 0.5s ease; }
  #care #care_color {
    opacity: 0;
    transition: all 0.5s ease; }
  #care:hover #care_gray, #care.color #care_gray {
    opacity: 0;
    transition: all 0.5s ease; }
  #care:hover #care_color, #care.color #care_color {
    opacity: 1;
    transition: all 0.5s ease; }

#retirement {
  cursor: pointer; }
  #retirement #retirement_gray {
    opacity: 1;
    transition: all 0.5s ease; }
  #retirement #retirement_color {
    opacity: 0;
    transition: all 0.5s ease; }
  #retirement:hover #retirement_gray, #retirement.color #retirement_gray {
    opacity: 0;
    transition: all 0.5s ease; }
  #retirement:hover #retirement_color, #retirement.color #retirement_color {
    opacity: 1;
    transition: all 0.5s ease; }

.mini-map .map-overlay {
  display: none !important; }

.map-overlay {
  pointer-events: none;
  padding: 100px;
  z-index: 10; }
  .map-overlay .hex-container .hex-title {
    font-family: 'IBM Plex Sans', mono;
    font-weight: bolder;
    font-size: 16px;
    line-height: 1.3em; }
  .map-overlay.homeOverlay {
    margin-top: 63px;
    margin-left: 33px; }
  .map-overlay.schoolOverlay {
    margin-top: 144px;
    margin-left: -10px; }
  .map-overlay.workOverlay {
    margin-top: -187px;
    margin-left: -57px; }
  .map-overlay.careOverlay {
    padding: 200px;
    margin-left: -66px;
    margin-top: 479px; }
  .map-overlay.retirementOverlay {
    margin-top: 277px;
    margin-left: -337px; }
  .map-overlay.communityOverlay {
    margin-top: 200px;
    margin-left: -188px; }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.grid {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  opacity: 0;
  z-index: 0; }

@media (max-width: 767px) {
  .grid-svg {
    width: 1000px; } }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.donut-chart text:last-child {
  display: none; }

.donut-chart {
  font-family: 'Bungee', cursive;
  font-size: 22px;
  line-height: 1.5em;
  max-width: 110px; }

.stat-circle-container {
  max-width: 110px; }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.provocation-hexagon {
  text-transform: uppercase;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 50px;
  position: relative;
  width: 175px;
  height: 202px; }
  .provocation-hexagon .provocation-hex-svg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10; }
  .provocation-hexagon:not(:first-child) {
    margin-top: 50px; }
  .provocation-hexagon .provocation-content {
    position: absolute;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 20;
    text-align: center;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
  .provocation-hexagon .provocation-title {
    font-family: 'Bungee', cursive;
    font-size: 24px;
    line-height: 1.5em;
    margin-bottom: 0px; }
  .provocation-hexagon .provocation-copy {
    font-family: 'IBM Plex Sans Condensed', sans-serif;
    font-size: 15px;
    line-height: 18px; }
  .provocation-hexagon .cls-1 {
    fill: none;
    stroke: #A18B00;
    stroke-miterlimit: 10;
    stroke-width: 1.14px;
    stroke-dasharray: 3.42 3.42; }
  .provocation-hexagon .cls-2 {
    fill: #A18B00; }

.first-provocation-container .provocation-hexagon {
  margin-left: -30px; }

.second-provocation-container .provocation-hexagon {
  margin-left: -125px; }

.retirement .provocation-content {
  color: #57448d; }

.home .provocation-content {
  color: #0060a0; }

.school .provocation-content {
  color: #008343; }

.community .provocation-content {
  color: #a18b00; }

.work .provocation-content {
  color: #f15a22; }

.care .provocation-content {
  color: #ed175e; }

.individual .provocation-content {
  color: #007e84; }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.hex-container {
  width: 100px;
  position: absolute;
  z-index: 30;
  top: -56px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .hex-container .hex-title {
    position: relative;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 20;
    color: white;
    font-family: 'Bungee', cursive;
    font-size: 14px;
    line-height: 1.3em;
    text-align: center;
    line-height: 1.2em; }
  .hex-container .hex-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10; }
  .hex-container.large {
    width: 190px;
    height: 219px;
    top: -236px;
    right: -94px; }
    .hex-container.large .hex-title {
      font-family: 'Bungee', cursive;
      font-size: 36px; }
  .hex-container.medium {
    width: 175px;
    height: 202px; }
    .hex-container.medium .hex-title {
      font-family: 'Bungee', cursive;
      font-size: 36px; }
  .hex-container.small {
    width: 134px;
    height: 154px; }
    .hex-container.small .hex-title {
      font-family: 'Bungee', cursive;
      font-size: 24px;
      line-height: 1.5em; }
  .hex-container.tiny {
    width: 100px;
    height: 115px; }
    .hex-container.tiny .hex-title {
      font-family: 'Bungee', cursive;
      font-size: 14px;
      line-height: 1.3em; }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.home .lottie-hotspot {
  cursor: pointer; }

.section {
  font-family: 'IBM Plex Sans', sans;
  font-size: 16px;
  line-height: 1.5em;
  color: #4a4a4a;
  position: absolute;
  padding-bottom: 100px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1366px;
  width: 100%;
  transition: all 1s ease;
  z-index: 30;
  background-image: linear-gradient(to bottom, rgba(255, 245, 217, 0) 2%, #fff5d9); }
  .section .lottie-hotspot {
    height: 400px; }
  .section .section-intro-container {
    padding: 0;
    -webkit-align-items: center;
            align-items: center; }
    .section .section-intro-container .intro-row {
      -webkit-align-items: center;
              align-items: center; }

.next-button {
  color: #fff5d9;
  font-family: 'Bungee', cursive;
  font-size: 20px;
  line-height: 1.5em;
  line-height: 1.2em;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: black;
  transition: all 0.5s ease;
  border: 1px solid transparent;
  width: 300px;
  margin: 40px auto 0 auto;
  display: block; }
  .next-button:hover {
    background-color: #fff5d9;
    background-color: transparent;
    color: black;
    transition: all 0.5s ease;
    border: 1px solid black; }
  .next-button:hover {
    text-decoration: none; }

.section-on .section {
  opacity: 1; }

.section-off .section {
  opacity: 0; }

.quotes-and-stats {
  font-family: 'IBM Plex Mono', mono;
  font-size: 12px;
  line-height: 1.5em; }

.home .quotes-and-stats a:hover {
  color: #00ffff; }

.care .quotes-and-stats a:hover {
  color: #ff459d; }

.retirement .quotes-and-stats a:hover {
  color: #ff00ff; }

.school .quotes-and-stats a:hover {
  color: #00FF00; }

.work .quotes-and-stats a:hover {
  color: #fcee21; }

.community .quotes-and-stats a:hover {
  color: #ffff00; }

.individual .quotes-and-stats a:hover {
  color: #00FFFF; }

.section-title {
  margin-top: 20px; }

.section-title,
.content-title {
  font-family: 'IBM Plex Mono', bold;
  font-weight: bold;
  font-size: 30px;
  line-height: 1.3em; }

.home .section-title {
  color: #0060a0; }

.retirement .section-title {
  color: #57448d; }

.individual .section-title {
  color: #007e84; }

.school .section-title {
  color: #008343; }

.care .section-title {
  color: #ed175e; }

.work .section-title {
  color: #f15a22; }

.community .section-title {
  color: #a18b00; }

.section-title,
.section-provocation:not(:last-child):not(:last-child),
.section-statistic {
  margin-bottom: 25px; }

.section-provocation {
  font-size: 24px;
  font-weight: bold;
  font-style: italic; }

.provocations-reveal {
  opacity: 0; }

.attribution {
  margin-top: 5px;
  display: block;
  color: #4a4a4a;
  text-decoration: underline;
  transition: all 0.5s ease; }
  .attribution:hover {
    transition: all 0.5s ease; }

.first-provocation-container {
  margin-left: -50px;
  margin-right: 50px; }

.second-provocation-container {
  padding-top: 110px; }

.section-provocations,
.build-item-content,
.hear-item-content {
  background-color: #328fce;
  color: white;
  padding: 50px; }

.build-item-img {
  width: 100%;
  height: auto; }

.build-item-img-container {
  text-align: center; }

.section .watch-item-img-container {
  text-align: center;
  margin-bottom: 15px; }

.build-item-copy {
  font-family: 'IBM Plex Mono', mono;
  font-size: 12px;
  line-height: 1.5em; }

.build-this-row {
  margin-top: 450px; }

.build-this-content-col {
  position: relative; }

.build-this-content-row {
  position: relative;
  margin-top: 94px; }
  .build-this-content-row .build-this-subtitle {
    position: absolute;
    top: -70px;
    left: 0px;
    width: 200px;
    font-family: 'IBM Plex Mono', mono;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    z-index: 50;
    color: #38393B; }

.watch-this-content-row {
  position: relative;
  margin-top: 240px; }
  .watch-this-content-row .watch-this-subtitle {
    position: absolute;
    top: -55px;
    left: 0px;
    width: 180px;
    font-family: 'IBM Plex Mono', mono;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    z-index: 50;
    color: #38393B; }
  .watch-this-content-row .hex-container {
    position: absolute;
    top: -170px;
    right: -65px; }

.build-item-line {
  border-right: 2px solid #328fce;
  position: absolute;
  right: 0;
  height: 100px;
  top: -50px;
  display: none; }

.watch-item-line {
  border-right: 2px solid #328fce;
  position: absolute;
  right: 0;
  height: 100px;
  top: -50px;
  z-index: 10; }

.top-content-container-container,
.build-this-container-container,
.hear-this-container-container {
  opacity: 0; }
  .top-content-container-container .hex-svg,
  .build-this-container-container .hex-svg,
  .hear-this-container-container .hex-svg {
    opacity: .8; }

.section .top-content-container {
  padding: 20px 0;
  position: relative;
  margin-bottom: 100px;
  margin-top: 180px;
  margin-left: 0;
  margin-right: 0; }
  .section .top-content-container .todays-subtitle {
    position: absolute;
    top: -100px;
    left: 140px;
    width: 120px;
    font-family: 'IBM Plex Mono', mono;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    z-index: 50;
    color: #38393B; }
  .section .top-content-container .hex-container {
    top: -130px;
    left: 20px; }

.hear-this-section {
  padding-bottom: 35px;
  padding-top: 50px;
  position: relative;
  margin-top: -200px;
  margin-bottom: 100px; }
  .hear-this-section .hear-this-subtitle {
    position: absolute;
    top: -40px;
    left: 110px;
    width: 120px;
    font-family: 'IBM Plex Mono', mono;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    z-index: 50;
    color: #38393B; }
  .hear-this-section .hex-container {
    position: absolute;
    top: -165px;
    left: -102px; }
  .hear-this-section .hear-this-line {
    border-left: 2px solid #328fce;
    position: absolute;
    left: 0;
    height: 100px;
    top: -50px;
    display: none; }

.section .stat-item {
  margin-bottom: 20px; }
  .section .stat-item div {
    -webkit-align-items: center;
            align-items: center; }
  .section .stat-item .quotes-and-stats {
    padding-left: 15px; }

.section-link {
  text-decoration: underline;
  transition: all 0.5s ease;
  color: #4a4a4a; }
  .section-link:hover {
    transition: all 0.5s ease;
    font-style: bold; }

.retirement .section-link:hover {
  color: #ff00ff; }

.home .section-link:hover {
  color: #00ffff; }

.care .section-link:hover {
  color: #ff459d; }

.community .section-link:hover {
  color: #ffff00; }

.school .section-link:hover {
  color: #00FF00; }

.work .section-link:hover {
  color: #fcee21; }

.home .statistics-container {
  border-color: #00ffff; }

.individual .statistics-container {
  border-color: #00FFFF; }

.retirement .statistics-container {
  border-color: #ff00ff; }

.hear-item-img-container {
  border-left: 2px solid #328fce; }

.build-item-content,
.hear-item-content,
.hear-item-img-container {
  padding: 25px; }

.section-intro-container,
.statistics-container {
  color: #4a4a4a; }

.stat-background {
  width: 100%;
  position: absolute;
  top: -80px; }

.stat-background-bottom {
  width: 100%;
  position: absolute; }

.todays-reality-content-container {
  border-right: 1px solid black;
  border-left: 1px solid black;
  padding: 0 15px; }

.home .todays-reality-content-container {
  border-right: 1px solid #0060a0;
  border-left: 1px solid #0060a0; }

.retirement .todays-reality-content-container {
  border-right: 1px solid #57448d;
  border-left: 1px solid #57448d; }

.school .todays-reality-content-container {
  border-right: 1px solid #008343;
  border-left: 1px solid #008343; }

.work .todays-reality-content-container {
  border-right: 1px solid #f15a22;
  border-left: 1px solid #f15a22; }

.individual .todays-reality-content-container {
  border-right: 1px solid #007e84;
  border-left: 1px solid #007e84; }

.care .todays-reality-content-container {
  border-right: 1px solid #ed175e;
  border-left: 1px solid #ed175e; }

.community .todays-reality-content-container {
  border-right: 1px solid #a18b00;
  border-left: 1px solid #a18b00; }

.stat-circle-container {
  display: inline-block;
  position: relative;
  width: 100%; }

.stat-circle-helper {
  margin-top: 100%; }

.stat-circle {
  text-align: center;
  border-radius: 100%;
  background-color: black;
  color: white;
  font-family: 'Bungee', cursive;
  font-size: 18px;
  line-height: .8em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: silver; }

.stat-unit {
  font-size: 10px; }

.home .stat-circle {
  background-color: #0060a0; }

.school .stat-circle {
  background-color: #008343; }

.retirement .stat-circle {
  background-color: #57448d; }

.care .stat-circle {
  background-color: #ed175e; }

.individual .stat-circle {
  background-color: #008343; }

.community .stat-circle {
  background-color: #a18b00; }

.work .stat-circle {
  background-color: #f15a22; }

.individual .stat-circle {
  background-color: #007e84; }

.section-intro {
  -webkit-column-count: 2;
          column-count: 2; }

.section-content-container {
  margin-top: 100px; }

.watch-item-title {
  font-family: 'IBM Plex Mono', bold;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.3em;
  text-transform: capitalize;
  margin-bottom: 10px; }

.watch-item-content {
  font-family: 'IBM Plex Mono', italic;
  font-size: 12px;
  line-height: 1.3em; }

.statistic-value-unit {
  font-family: 'IBM Plex Mono', mono;
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 15px;
  text-transform: uppercase; }

.statistic-clear {
  clear: both; }

.build-this-badge,
.hear-this-badge,
.watch-this-badge {
  width: 100%; }

.build-this-badge,
.watch-this-badge {
  margin-top: 100px; }

.build-row {
  margin-bottom: 20px;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px; }

.section .watch-this-content-col {
  padding-top: 15px; }

.retirement .build-row, .retirement .hear-this-container, .retirement .watch-this-content-col {
  background-color: rgba(115, 86, 199, 0.8); }

.home .build-row, .home .hear-this-container, .home .watch-this-content-col {
  background-color: rgba(43, 125, 191, 0.8); }

.care .build-row, .care .hear-this-container, .care .watch-this-content-col {
  background-color: rgba(240, 83, 117, 0.8); }

.work .build-row, .work .hear-this-container, .work .watch-this-content-col {
  background-color: rgba(244, 125, 32, 0.8); }

.community .build-row, .community .hear-this-container, .community .watch-this-content-col {
  background-color: rgba(189, 166, 25, 0.8); }

.individual .build-row, .individual .hear-this-container, .individual .watch-this-content-col {
  background-color: rgba(0, 149, 152, 0.8); }

.school .build-row, .school .hear-this-container, .school .watch-this-content-col {
  background-color: rgba(0, 149, 75, 0.8); }

.section .watch-row {
  margin-left: 0px;
  margin-right: 0px;
  color: white;
  padding-top: 15px;
  padding-bottom: 20px;
  position: relative;
  z-index: 20; }

.section .watch-row-border {
  margin-top: 30px;
  border: 0px;
  border-bottom: 1px solid white;
  border-style: dashed; }

.section .watch-row:last-child {
  border-bottom: 0px; }

.hear-this-badge {
  margin-top: 550px; }

.section .hear-this-container {
  margin-bottom: 20px;
  color: white;
  padding: 20px 20px 20px 10px; }

.hear-this-box {
  padding: 20px;
  position: relative;
  color: white;
  padding-left: 60px; }

.hear-item-icon {
  width: 50px;
  height: auto; }

.hear-item-img {
  width: 100%;
  height: auto; }

.hear-item-line {
  content: '';
  width: 100%;
  height: 100px;
  padding: 0;
  margin: 0;
  top: 0;
  right: 0; }

.hear-item-line {
  border-left: 2px solid #328fce; }

.hear-item-location {
  margin-left: 10px;
  font-family: 'IBM Plex Mono', mono;
  font-size: 12px;
  line-height: 1.5em;
  display: none; }

.build-item-img-container,
.hear-item-img-container,
.hear-item-icon-container,
.watch-item-img-container {
  width: 100%;
  text-align: center; }

.hear-item-name {
  font-family: 'Bungee', cursive;
  font-size: 20px;
  line-height: 1.5em;
  text-transform: capitalize;
  margin-bottom: 0px; }

.hear-item-quote {
  border: 0px;
  border-top: 1px solid white;
  border-style: dashed;
  font-family: 'IBM Plex Mono', mono;
  font-size: 12px;
  line-height: 1.5em;
  padding-top: 10px; }

.build-item-title {
  font-family: 'Bungee', cursive;
  font-size: 20px;
  line-height: 1.5em;
  line-height: 1.2em;
  text-transform: capitalize;
  margin-bottom: 10px; }

.hear-item-name {
  margin-bottom: 10px; }

.hear-item-context {
  font-style: italic;
  font-family: 'IBM Plex Mono', mono;
  font-size: 12px;
  line-height: 1.5em;
  margin-bottom: 10px;
  margin-top: -10px; }

@media (max-width: 991px) {
  .section {
    margin-top: 48vh;
    background-color: rgba(255, 245, 217, 0.9); }
  .section-intro-container .section-content-container {
    margin-top: 0; }
  .hear-this-section {
    margin-top: 250px !important;
    padding-bottom: 0; }
    .hear-this-section .hex-container {
      left: 0;
      right: 0;
      margin: auto; }
  .next-button {
    width: 96%;
    display: block;
    margin-right: auto;
    margin-left: auto; }
  .section .lottie-hotspot {
    position: fixed;
    height: 50vh;
    width: 100%;
    left: auto;
    top: 0; }
  .first-provocation-container .provocation-hexagon {
    margin-left: 0; }
  .second-provocation-container .provocation-hexagon {
    margin-left: 0; }
  .watch-this-content-col {
    margin-left: 15px;
    margin-right: 15px; }
  .stat-background {
    display: none; }
  .stat-circle {
    height: 107px;
    width: 107px;
    font-size: 24px; }
  .stat-circle-container {
    display: inline-block;
    position: relative;
    width: 107px; }
  .section .top-content-container .todays-subtitle {
    left: 15px;
    font-size: 11px;
    width: 80px;
    line-height: 14px;
    top: -100px;
    left: 0px;
    top: -60px; }
  .hear-this-section .hear-this-subtitle {
    left: 15px;
    font-size: 11px;
    width: 80px;
    line-height: 14px;
    top: -100px;
    left: 0px;
    top: -35px; }
  .watch-this-content-row .watch-this-subtitle {
    left: 15px;
    font-size: 11px;
    width: 80px;
    line-height: 14px;
    top: -100px;
    top: -70px;
    left: 0; }
  .section .build-row {
    margin-right: 0px;
    margin-left: 0px; }
  .build-this-content-row .build-this-subtitle {
    left: 15px;
    font-size: 11px;
    width: 80px;
    line-height: 14px;
    top: -100px; }
  .section .hear-this-container {
    margin: 0 0 20px 0; }
  .build-item-img, .hear-item-img {
    max-width: 120px; }
  .watch-row .build-item-img {
    max-width: 100%; }
  .hear-item-img, .build-item-img-container {
    margin-bottom: 10px; }
  .hear-this-line, .build-item-line {
    display: none; }
  .section .top-content-container .hex-container {
    left: 0;
    right: 0;
    margin: auto; }
  .section .top-content-container {
    margin-top: 220px; }
    .section .top-content-container .stat-background, .section .top-content-container .stat-background-bottom {
      display: none; }
    .section .top-content-container .todays-reality-content-container {
      border: 0; }
    .section .top-content-container .stat-item {
      background: white;
      padding-top: 20px;
      padding-bottom: 20px; }
  .build-this-content-row {
    margin-top: 360px; }
  .hex-container.large {
    left: 0;
    right: 0;
    margin: auto; }
  .watch-this-content-row {
    margin-top: 160px;
    position: relative; }
    .watch-this-content-row .hex-container {
      position: absolute;
      top: -170px;
      left: 0;
      right: 0;
      margin: auto; }
  .watch-item-img-container {
    margin-bottom: 15px;
    margin-top: 0px; }
  .section .provocations-container {
    margin-top: 60px; }
  .section .second-provocation-container {
    padding-top: 20px; }
  .section .provocation-container {
    margin: 0;
    text-align: -webkit-center; }
  .section .provocation-hexagon {
    margin-bottom: 0; }
  .section .section-intro {
    -webkit-column-count: 1;
            column-count: 1; } }

@media screen and (max-width: 1300px) {
  .section .top-content-container {
    padding-top: 10px;
    padding-bottom: 10px; } }

@media screen and (max-width: 1200px) {
  .section .top-content-container {
    padding-top: 0px;
    padding-bottom: 0px; } }

@media screen and (max-width: 1200px) {
  .stat-background {
    top: -76px; } }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.tooltips-container {
  position: absolute;
  z-index: 50; }

.care-site-tooltip {
  transition: all 0.5s ease;
  opacity: 0;
  position: absolute;
  pointer-events: none; }
  .care-site-tooltip.show-me {
    opacity: 1; }

.school-tooltip {
  top: 273px;
  left: 120px; }

.retirement-tooltip {
  top: 568px;
  left: 320px; }

.work-tooltip {
  top: 203px;
  left: 1064px; }

.home-tooltip {
  top: 545px;
  left: 530px; }

.community-tooltip {
  top: 142px;
  left: 594px; }

.care-tooltip {
  top: 491px;
  left: 1050px; }

@media screen and (max-width: 1366px) {
  .care-tooltip {
    top: 300px;
    left: 70vw; }
  .work-tooltip {
    top: 110px;
    left: 77vw; }
  .community-tooltip {
    top: 100px;
    left: 42vw; }
  .retirement-tooltip {
    top: 400px;
    left: 20vw; }
  .home-tooltip {
    top: 400px;
    left: 40vw; } }

@media (max-width: 767px) {
  .tooltips-container {
    display: none; } }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.hex-overlay-container {
  width: 100px;
  position: absolute;
  z-index: 100;
  top: -56px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .hex-overlay-container .hex-title {
    text-align: center;
    color: white;
    position: relative;
    z-index: 20;
    font-family: 'Bungee', cursive;
    font-size: 16px;
    line-height: 1.3em;
    margin-bottom: 5px; }
  .hex-overlay-container .hex-copy {
    position: relative;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 20;
    color: white;
    font-family: 'IBM Plex Sans', sans;
    font-size: 15px;
    line-height: 1.5em;
    text-align: center; }
  .hex-overlay-container .hex-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10; }
  .hex-overlay-container.large {
    width: 225px;
    height: 260px;
    top: -275px;
    right: -111px; }
    .hex-overlay-container.large .hex-title {
      font-family: 'Bungee', cursive;
      font-size: 36px;
      line-height: 40px; }
  .hex-overlay-container.medium {
    width: 175px;
    height: 202px; }
  .hex-overlay-container.small {
    width: 134px;
    height: 154px; }
    .hex-overlay-container.small .hex-title {
      font-family: 'Bungee', cursive;
      font-size: 24px;
      line-height: 1.5em; }
  .hex-overlay-container.tiny {
    width: 100px;
    height: 115px; }
    .hex-overlay-container.tiny .hex-title {
      font-family: 'Bungee', cursive;
      font-size: 14px;
      line-height: 1.3em; }

@media (max-width: 767px) {
  .element {
    font-weight: bold; } }

.text-center {
  text-align: center; }

.portrait .lottie-container {
  display: none; }

.landscape .png-container .individual-visited {
  display: none; }

.mini-map #home_title, .mini-map #care_title, .mini-map #work_title, .mini-map #school_title, .mini-map #retirement_title, .mini-map #community_title,
.mini-map #home_connection, .mini-map #care_connection, .mini-map #work_connection, .mini-map #school_connection, .mini-map #retirement_connection,
.mini-map #home_dot, .mini-map #care_dot, .mini-map #work_dot, .mini-map #school_dot, .mini-map #retirement_dot {
  opacity: 1; }

#home_title, #care_title, #work_title, #school_title, #retirement_title, #community_title {
  opacity: 1;
  transition: all 0.2s ease; }

.png-container {
  pointer-events: none;
  z-index: 15; }
  .png-container .visited-container {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.2s ease;
    background-size: contain; }
  .png-container .home-visited {
    background-image: url(/static/media/home-visited@2x.04c8ac05.png); }
  .png-container .care-visited {
    background-image: url(/static/media/care-visited@2x.7d7f3f13.png); }
  .png-container .work-visited {
    background-image: url(/static/media/work-visited@2x.70f4842b.png); }
  .png-container .school-visited {
    background-image: url(/static/media/school-visited@2x.a4110b8a.png); }
  .png-container .retirement-visited {
    background-image: url(/static/media/retirement-visited@2x.375e0a8d.png); }
  .png-container .community-visited {
    background-image: url(/static/media/community-visited@2x.90d091bc.png); }
  .png-container .all-visited {
    background-image: url(/static/media/all-visited@2x.2f25571e.png); }
  .png-container .individual-visited {
    background-image: url(/static/media/individual.3a42786c.png); }

.png-container.portrait .home-visited {
  background-image: url(/static/media/portrait-home-visited@2x.5c1355cc.png); }

.png-container.portrait .care-visited {
  background-image: url(/static/media/portrait-care-visited@2x.bfe5d088.png); }

.png-container.portrait .work-visited {
  background-image: url(/static/media/portrait-work-visited@2x.d4e529df.png); }

.png-container.portrait .school-visited {
  background-image: url(/static/media/portrait-school-visited@2x.8de8c9d8.png); }

.png-container.portrait .retirement-visited {
  background-image: url(/static/media/portrait-retirement-visited@2x.9983f79a.png); }

.png-container.portrait .community-visited {
  background-image: url(/static/media/portrait-community-visited@2x.0356d26e.png); }

.png-container.portrait .all-visited {
  background-image: url(/static/media/portrait-all-visited@2x.c14ac30b.png); }

.show-individual-visited .png-container .individual-visited {
  opacity: 1;
  transition: all 0.5s ease; }

.show-home-visited .png-container .home-visited {
  opacity: 1;
  transition: all 2s ease;
  transition-delay: 1s; }

.show-care-visited .png-container .care-visited {
  opacity: 1;
  transition: all 2s ease;
  transition-delay: 1s; }

.show-work-visited .png-container .work-visited {
  opacity: 1;
  transition: all 2s ease;
  transition-delay: 1s; }

.show-community-visited .png-container .community-visited {
  opacity: 1;
  transition: all 2s ease;
  transition-delay: 1s; }

.show-school-visited .png-container .school-visited {
  opacity: 1;
  transition: all 2s ease;
  transition-delay: 1s; }

.show-retirement-visited .png-container .retirement-visited {
  opacity: 1;
  transition: all 2s ease;
  transition-delay: 1s; }

.show-all-visited .png-container .all-visited {
  opacity: 1;
  transition: all 2s ease;
  transition-delay: 1s; }

.website-title {
  position: absolute;
  margin-left: auto;
  top: 0px;
  text-align: center;
  width: 100%;
  padding: 50px;
  font-family: 'Bungee', cursive;
  font-size: 24px;
  line-height: 1.5em;
  color: #7f7f7f;
  z-index: 35;
  transition: all 0.5s ease;
  pointer-events: none; }

.mobile-nav-container {
  background-color: rgba(255, 245, 217, 0);
  position: fixed;
  width: 100%;
  height: 65px;
  top: 0;
  left: 0; }

@media (max-width: 767px) {
  .mobile-nav-container {
    background-color: rgba(255, 245, 217, 0.9); }
  .website-title {
    top: 20px;
    position: fixed;
    padding: 0;
    color: #4a4a4a;
    text-transform: uppercase;
    font-size: 15px;
    font-family: 'IBM Plex Mono', italic; } }

.section-on .website-title {
  opacity: 0;
  transition: all 0.5s ease; }

@media (max-width: 767px) {
  .section-on .website-title {
    opacity: 1;
    transition: all 0.5s ease; } }

.main-container {
  max-width: 1366px;
  margin: auto;
  position: relative; }
  @media (max-width: 768px) {
    .main-container {
      top: 0px; } }

.nav {
  position: relative;
  z-index: 40; }

.section-on .maxi-map-container, .section-on .png-container {
  position: fixed; }

.maxi-map-container,
.maxi-map,
.png-container,
.lottie-container,
.ambient-container-container,
.section {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; }

.maxi-map-container {
  z-index: 10; }

.lottie-container {
  position: fixed;
  top: 0;
  z-index: 20;
  transition: opacity 1s ease; }

.section {
  z-index: 30; }

.section-off .lottie-container,
.home .lottie-container {
  opacity: 0;
  transition: opacity 1s ease; }

.lottie-unclickable {
  pointer-events: none; }

.lottie-off {
  opacity: 0; }

.mini-map,
.share-container,
.site-info-container {
  position: fixed;
  font-family: 'IBM Plex Mono', mono;
  color: #4a4a4a; }

.mobile-back-to-map {
  opacity: 1;
  display: none;
  position: fixed;
  top: 17px;
  left: 15px;
  width: 38px;
  transition: all 0.5s ease; }
  .mobile-back-to-map.mobile-back-to-map-off {
    opacity: 0; }
  .mobile-back-to-map .mobile-back-to-map-svg {
    width: 100%;
    height: auto; }
    .mobile-back-to-map .mobile-back-to-map-svg:hover, .mobile-back-to-map .mobile-back-to-map-svg:active {
      stroke: black; }

@media (max-width: 767px) {
  .mobile-back-to-map {
    display: block;
    opacity: 1; } }

.mini-map {
  cursor: pointer;
  top: 50px;
  left: 50px;
  padding: 0;
  height: 57px;
  width: 57px;
  border: 1px solid transparent;
  background-color: transparent;
  transition: all 0.5s ease;
  transition-delay: 0.2s; }
  .mini-map.mini-map-off {
    opacity: 0; }
  .mini-map .mini-map-svg {
    width: 300px;
    height: 170px;
    opacity: 0;
    transition: all 0.5s ease; }
  .mini-map .zoom-icon {
    cursor: pointer;
    height: 55px;
    width: 55px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    opacity: .5;
    transition: all 0.5s ease; }
    .mini-map .zoom-icon polyline {
      stroke-width: 10px; }
  @media (max-width: 768px) {
    .mini-map {
      display: none; } }

.mini-map:hover {
  height: 172px;
  width: 302px;
  border: 1px solid #7f7f7f;
  background-color: #f2f2f2;
  transition: all 0.5s ease; }
  .mini-map:hover .mini-map-svg {
    opacity: 1;
    transition: all 0.5s ease;
    transition-delay: 0.2s; }

.zoom-icon:hover {
  opacity: 1;
  transition: all 0.5s ease; }

.share-container {
  cursor: pointer;
  right: 50px;
  top: 50px;
  border: 1px solid transparent;
  width: 55px;
  height: 55px;
  text-transform: uppercase;
  transition: all 0.5s ease;
  transition-delay: .1s; }
  .share-container .share-icon {
    top: 0;
    left: 0;
    position: absolute;
    height: 55px;
    width: 55px;
    padding: 15px;
    text-align: center;
    opacity: .5;
    text-align: center; }
    .share-container .share-icon svg {
      height: 25px; }
  .share-container .share-links {
    position: absolute;
    line-height: 2em;
    top: 75px;
    right: 8px;
    width: 35px;
    height: 150px;
    transition: all 0.5s ease; }
    .share-container .share-links .share-link {
      cursor: pointer;
      margin-bottom: 25px; }
    .share-container .share-links .share-link-one {
      opacity: 0;
      transition: all 0.5s ease;
      transition-delay: .15s; }
    .share-container .share-links .share-link-two {
      opacity: 0;
      transition: all 0.5s ease;
      transition-delay: .1s; }
    .share-container .share-links .share-link-three {
      opacity: 0;
      transition: all 0.5s ease;
      transition-delay: .05s; }
    .share-container .share-links .share-link-four {
      opacity: 0;
      transition: all 0.5s ease; }
  @media (max-width: 768px) {
    .share-container {
      display: none; } }

.share-container:hover {
  height: 250px;
  transition: all 0.5s ease; }
  .share-container:hover .share-link-one {
    opacity: 0.5;
    transition: all 0.5s ease; }
  .share-container:hover .share-link-two {
    opacity: 0.5;
    transition: all 0.5s ease;
    transition-delay: .05s; }
  .share-container:hover .share-link-three {
    opacity: 0.5;
    transition: all 0.5s ease;
    transition-delay: .1s; }
  .share-container:hover .share-link-four {
    opacity: 0.5;
    transition: all 0.5s ease;
    transition-delay: .15s; }
  .share-container:hover .share-link:hover {
    opacity: 1;
    transition: all 0.5s ease; }

.site-info-container {
  bottom: 50px;
  right: 50px;
  width: 225px;
  height: 55px;
  font-size: 16px;
  line-height: 50px;
  text-align: right;
  border: 1px solid transparent;
  background-color: transparent;
  transition: all 0.5s ease;
  transition-delay: .2s; }
  .site-info-container .info-icon {
    bottom: 0;
    right: 0;
    position: absolute;
    height: 55px;
    width: 55px;
    padding: 15px;
    text-align: center;
    opacity: .5; }
    .site-info-container .info-icon svg {
      position: relative;
      height: 25px;
      bottom: 15px; }
  .site-info-container .site-info-links {
    position: absolute;
    bottom: 75px;
    right: 20px; }
    .site-info-container .site-info-links .intro-link:hover, .site-info-container .site-info-links .about-link:hover {
      cursor: pointer;
      font-weight: bold;
      transition: all 0.5s ease;
      transition-delay: 0s; }
    .site-info-container .site-info-links .copyright {
      opacity: 0;
      transition: all 0.5s ease;
      transition-delay: .15s; }
    .site-info-container .site-info-links .about-link {
      opacity: 0;
      transition: all 0.5s ease;
      transition-delay: .1s; }
    .site-info-container .site-info-links .intro-link {
      opacity: 0;
      transition: all 0.5s ease;
      transition-delay: .05s; }
  .site-info-container:hover {
    height: 245px;
    border: 1px solid #7f7f7f;
    background-color: #f2f2f2;
    transition: all 0.5s ease; }
    .site-info-container:hover .copyright {
      opacity: .5;
      transition: all 0.5s ease;
      transition-delay: .05s; }
    .site-info-container:hover .about-link {
      opacity: 1;
      transition: all 0.5s ease;
      transition-delay: .1s; }
    .site-info-container:hover .intro-link {
      opacity: 1;
      transition: all 0.5s ease;
      transition-delay: .15s; }
  @media (max-width: 768px) {
    .site-info-container {
      display: none; } }

.menu-icon {
  cursor: pointer;
  position: fixed;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  opacity: 1;
  transition: all 0.5s ease; }
  .menu-icon path:hover, .menu-icon path:active {
    fill: black; }
  @media (min-width: 768px) {
    .menu-icon {
      display: none; } }

.modal-open .menu-icon {
  opacity: 0;
  transition: all 0.5s ease; }

/* TODO: Only import the fonts we need */

.App {
  position: relative;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

