@import 'shared';

.anim-ambient-container {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 18;
  pointer-events: none;
  // transition: all 1s ease;

  &.ambient-off {
    opacity: 0;
  }
}

@include respond-below(sm) {
  .anim-ambient-container {
    // display: none;
  }
}