@import 'shared';

.portrait {
  .lottie-container {
    display: none;
  }

  .png-container {
    .all-visited, .home-visited, .care-visited, .school-visited,
    .community-visited, .work-visited, .retirement-visited {
      // display: none;
    }
  }
}

.landscape {
  .png-container {
    .individual-visited {
      display: none;
    }
  }
}

.mini-map {
  #home_title, #care_title, #work_title, #school_title, #retirement_title, #community_title,
  #home_connection, #care_connection, #work_connection, #school_connection, #retirement_connection,
  #home_dot, #care_dot, #work_dot, #school_dot, #retirement_dot  {
    opacity: 1;
  }
}

#home_title, #care_title, #work_title, #school_title, #retirement_title, #community_title {
  opacity: 1;
  transition: $transition-fast;
}

.png-container {
  pointer-events: none;
  z-index: 15;

  .visited-container {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: $transition-fast;
    background-size: contain;
  }

  .home-visited {
    background-image: url(./img/map/home-visited@2x.png);
  }

  .care-visited {
    background-image: url(./img/map/care-visited@2x.png);
  }

  .work-visited {
    background-image: url(./img/map/work-visited@2x.png);
  }

  .school-visited {
    background-image: url(./img/map/school-visited@2x.png);
  }

  .retirement-visited {
    background-image: url(./img/map/retirement-visited@2x.png);
  }

  .community-visited {
    background-image: url(./img/map/community-visited@2x.png);
  }

  .all-visited {
    background-image: url(./img/map/all-visited@2x.png);
  }

  .individual-visited {
    background-image: url(./img/map/individual.png);
  }
}

.png-container.portrait {
  .home-visited {
    background-image: url(./img/map/portrait-home-visited@2x.png);
  }

  .care-visited {
    background-image: url(./img/map/portrait-care-visited@2x.png);
  }

  .work-visited {
    background-image: url(./img/map/portrait-work-visited@2x.png);
  }

  .school-visited {
    background-image: url(./img/map/portrait-school-visited@2x.png);
  }

  .retirement-visited {
    background-image: url(./img/map/portrait-retirement-visited@2x.png);
  }

  .community-visited {
    background-image: url(./img/map/portrait-community-visited@2x.png);
  }

  .all-visited {
    background-image: url(./img/map/portrait-all-visited@2x.png);
  }
}

.show-individual-visited {
  .png-container {
    .individual-visited {
      opacity: 1;
      transition: $transition;
    }
  }
}

.show-home-visited {
  .png-container {
    .home-visited {
      opacity: 1;
      transition: $transition-extra-slow;
      transition-delay: 1s;
    }
  }
}

.show-care-visited {
  .png-container {
    .care-visited {
      opacity: 1;
      transition: $transition-extra-slow;
      transition-delay: 1s;
    }
  }
}

.show-work-visited {
  .png-container {
    .work-visited {
      opacity: 1;
      transition: $transition-extra-slow;
      transition-delay: 1s;
    }
  }
}

.show-community-visited {
  .png-container {
    .community-visited {
      opacity: 1;
      transition: $transition-extra-slow;
      transition-delay: 1s;
    }
  }
}

.show-school-visited {
  .png-container {
    .school-visited {
      opacity: 1;
      transition: $transition-extra-slow;
      transition-delay: 1s;
    }
  }
}

.show-retirement-visited {
  .png-container {
    .retirement-visited {
      opacity: 1;
      transition: $transition-extra-slow;
      transition-delay: 1s;
    }
  }
}

.show-all-visited {
  .png-container {
    .all-visited {
      opacity: 1;
      transition: $transition-extra-slow;
      transition-delay: 1s;
    }
  }
}

.website-title {
  position: absolute;
  margin-left: auto;
  top: 0px;
  text-align: center;
  width: 100%;
  padding: $standard-padding;
  @include title-4();
  color: map-get($grayColors, medium);
  z-index: 35;
  transition: $transition;
  pointer-events: none;
}

.mobile-nav-container {
  background-color: rgba(255,245,217,0);
  position: fixed;
  width: 100%;
  height: 65px;
  top: 0;
  left: 0;
  // -webkit-box-shadow: 0px 0px 2px 0px rgba(196,196,196,1);
  // -moz-box-shadow: 0px 0px 2px 0px rgba(196,196,196,1);
  // box-shadow: 0px 0px 2px 0px rgba(196,196,196,1);  
}

@include respond-below(sm) {
  .mobile-nav-container {
    background-color: rgba(255,245,217,0.9);
  }

  .website-title {
    top: 20px;
    position: fixed;
    padding: 0;
    color: map-get($grayColors, dark);
    text-transform: uppercase;
    font-size: 15px;
    font-family: 'IBM Plex Mono', italic;
  }
}

.section-on .website-title {
  opacity: 0;
  transition: $transition;
}

@include respond-below(sm) {
  .section-on .website-title {
    opacity: 1;
    transition: $transition;
  }

  // .home .website-title {
  //   color: map-get($homeColors, dark);
  // }

  // .retirement .website-title {
  //   color: map-get($retirementColors, dark);
  // }

  // .care .website-title {
  //   color: map-get($careColors, dark);
  // }

  // .work .website-title {
  //   color: map-get($workColors, dark);
  // }

  // .school .website-title {
  //   color: map-get($schoolColors, dark);
  // }

  // .community .website-title {
  //   color: map-get($communityColors, dark);
  // } 
  
  // .individual .website-title {
  //   color: map-get($individualColors, dark);
  // }  

}

.main-container {
  max-width: $max-width;
  margin: auto;
  position: relative;

  @media (max-width: $breakpoint) {
    top: 0px;
  }
}

.nav {
  position: relative;
  z-index: 40;
}

.section-on {
  .maxi-map-container, .png-container {
    position: fixed;
  }
}

.maxi-map-container,
.maxi-map,
.png-container,
.lottie-container,
.ambient-container-container,
.section {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.maxi-map-container {
  z-index: 10;
}

.lottie-container {
  position: fixed;
  top: 0;
  z-index: 20;
  transition: opacity 1s ease;
}

.section {
  z-index: 30;
}

.section-off .lottie-container,
.home .lottie-container {
  opacity: 0;
  transition: opacity 1s ease;
}

.lottie-unclickable {
  pointer-events: none;
}

.lottie-off {
  opacity: 0;
}

.mini-map,
.share-container,
.site-info-container {
  position: fixed;
  font-family: 'IBM Plex Mono', mono;
  color: map-get($grayColors, dark);
}

.mobile-back-to-map {
  opacity: 1;
  display: none;
  position: fixed;
  top: 17px;
  left: 15px;
  width: 38px;
  transition: all 0.5s ease;
  
  &.mobile-back-to-map-off {
    opacity: 0;
  }

  .mobile-back-to-map-svg {
    width: 100%;
    height: auto;

    &:hover, &:active {
      stroke: black;
    }
  }
}

@include respond-below(sm) {
  .mobile-back-to-map {
    display: block;
    opacity: 1;
  }
}

.mini-map {

  &.mini-map-off {
    opacity: 0;
  }
  cursor: pointer;
  top: $standard-padding;
  left: $standard-padding;
  padding: 0;
  height: 57px;
  width: 57px;
  border: 1px solid transparent;
  background-color: transparent;

  transition: $transition;
  transition-delay: $transition-delay*2;

  .mini-map-svg {
    width: 300px;
    height: 170px;
    opacity: 0;
    transition: $transition;
  }

  .zoom-icon {
    cursor: pointer;
    height: 55px;
    width: 55px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    opacity: .5;
    transition: $transition;

    polyline {
      stroke-width: 10px;
    }
  }

  @media (max-width: $breakpoint) {
    display: none;
  }
}

.mini-map:hover {
  height: 172px;
  width: 302px;
  border: 1px solid map-get($grayColors, medium);
  background-color: map-get($grayColors, light);
  transition: $transition;

  .mini-map-svg {
    opacity: 1;
    transition: $transition;
    transition-delay: $transition-delay*2;
  }
}

.zoom-icon:hover {
  opacity: 1;
  transition: $transition;
}

.share-container {
  cursor: pointer;
  right: $standard-padding;
  top: $standard-padding;
  border: 1px solid transparent;
  width: 55px;
  height: 55px;
  text-transform: uppercase;
  transition: $transition;
  transition-delay: .1s;

  .share-icon {
    top: 0;
    left: 0;
    position: absolute;
    height: 55px;
    width: 55px;
    padding: 15px;
    text-align: center;
    opacity: .5;
    text-align: center;

    svg {
      height: 25px;
    }
  }

  .share-links {
    position: absolute;
    line-height: 2em;
    top: 50px + 25px;
    right: 8px;
    width: 35px;
    height: 150px;
    transition: $transition;

    .share-link {
      cursor: pointer;
      margin-bottom: 25px;
    }

    .share-link-one {
      opacity: 0;
      transition: $transition;
      transition-delay: .15s;
    }

    .share-link-two {
      opacity: 0;
      transition: $transition;
      transition-delay: .1s;
    }

    .share-link-three {
      opacity: 0;
      transition: $transition;
      transition-delay: .05s;
    }

    .share-link-four {
      opacity: 0;
      transition: $transition;
    }
  }

  @media (max-width: $breakpoint) {
    display: none;
  }
}

.share-container:hover {
  height: 50px + 200px;
  transition: $transition;

  .share-link-one {
    opacity: 0.5;
    transition: $transition;
  }

  .share-link-two {
    opacity: 0.5;
    transition: $transition;
    transition-delay: .05s;
  }

  .share-link-three {
    opacity: 0.5;
    transition: $transition;
    transition-delay: .1s;
  }

  .share-link-four {
    opacity: 0.5;
    transition: $transition;
    transition-delay: .15s;
  }

  .share-link:hover {
    opacity: 1;
    transition: $transition;
  }
}

.site-info-container {
  bottom: $standard-padding;
  right: $standard-padding;
  width: 225px;
  height: 55px;

  font-size: 16px;
  line-height: 50px;
  text-align: right;

  border: 1px solid transparent;
  background-color: transparent;

  transition: $transition;
  transition-delay: .2s;

  .info-icon {
    bottom: 0;
    right: 0;
    position: absolute;
    height: 55px;
    width: 55px;
    padding: 15px;
    text-align: center;
    opacity: .5;

    svg {
      position: relative;
      height: 25px;
      bottom: 15px;
    }
  }

  .site-info-links {
    position: absolute;
    bottom: 50px + 25px;
    right: $standard-padding-2;

    .intro-link:hover, .about-link:hover {
      cursor: pointer;
      font-weight: bold;
      transition: $transition;
      transition-delay: 0s;
    }

    .copyright {
      opacity: 0;
      transition: $transition;
      transition-delay: .15s;
    }

    .about-link {
      opacity: 0;
      transition: $transition;
      transition-delay: .1s;
    }

    .intro-link {
      opacity: 0;
      transition: $transition;
      transition-delay: .05s;
    }
  }

  &:hover {
    height: 55px + 25px + 150px + 15px;
    border: 1px solid map-get($grayColors, medium);
    background-color: map-get($grayColors, light);
    transition: $transition;

    .copyright {
      opacity: .5;
      transition: $transition;
      transition-delay: .05s;
    }

    .about-link {
      opacity: 1;
      transition: $transition;
      transition-delay: .1s;
    }

    .intro-link {
      opacity: 1;
      transition: $transition;
      transition-delay: .15s;
    }
  }

  @media (max-width: $breakpoint) {
    display: none;
  }
}

.menu-icon {
  cursor: pointer;
  position: fixed;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  opacity: 1;
  transition: $transition;

  path:hover, path:active {
    fill: black;
  }

  @media (min-width: $breakpoint) {
    display: none;
  }
}

.modal-open {
  .menu-icon {
    opacity: 0;
    transition: $transition;
  }
}
